import React, { FC, PropsWithChildren, memo, MouseEvent, useState } from 'react';
import { Typography } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import s from './index.module.scss';
import classNames from 'classnames';

interface ParagraphProps extends PropsWithChildren {
  className?: string;
  rows?: number;
  expandable?: boolean;
}

export const Paragraph: FC<ParagraphProps> = memo((props) => {
  const { children, className, rows, expandable = true } = props;

  const [expanded, setExpanded] = useState(false);

  const { t } = useAppTranslation('common');

  const stopPropagation = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  const handleExpandChange = (e: MouseEvent<HTMLElement>, info: { expanded: boolean }): void => {
    setExpanded(info.expanded);
  };

  return (
    <Typography.Paragraph
      ellipsis={{
        rows,
        expandable: expandable ? 'collapsible' : false,
        symbol: t(expanded ? 'Hide' : 'View more'),
        onExpand: handleExpandChange,
      }}
      className={classNames(s.paragraph, className)}
      onClick={stopPropagation}
    >
      {children}
    </Typography.Paragraph>
  );
});
