import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getBase64string } from '../../model/selectors/getBase64string';
import { renderPDFActions } from '../../model/slice/renderPDFSlice';

interface HookApi {
  isOpened: boolean;
  base64String: string | undefined;
  openRenderPDFModal: (base64String: string) => void;
  closeRenderPDFModal: () => void;
}

export const useRenderPDFModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const base64String = useAppSelector(getBase64string) || undefined;

  const openRenderPDFModal = useCallback(
    (base64String: string) => {
      dispatch(renderPDFActions.openModal(base64String));
    },
    [dispatch],
  );

  const closeRenderPDFModal = useCallback(() => {
    dispatch(renderPDFActions.closeModal());
  }, [dispatch]);

  return {
    isOpened: Boolean(base64String),
    base64String,
    openRenderPDFModal,
    closeRenderPDFModal,
  };
};
