import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { getMinMaxSquare, useGetPaginatedWarehousesQuery, Warehouse } from 'entities/Warehouse';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { transformWarehouseListFilters } from '../utils/helpers/transformWarehouseListFilters';
import { WarehouseListFilter } from './WarehouseListFilter';
import { stepperModalActions } from 'features/StepperModal';

interface ChooseWarehouseStepProps {
  onSuccess: () => void;
}

export const ChooseWarehouseStep: FC<ChooseWarehouseStepProps> = memo((props) => {
  const { t } = useAppTranslation('user');

  const dispatch = useAppDispatch();

  const [showNextButtonWithoutWarehouses, setShowNextButtonWithoutWarehouses] = useState(true);

  const { setFiltersOpened, appliedFilters, tags } = useTableFilterContext();

  const goToChoosePermissionStep = useCallback((): void => {
    dispatch(stepperModalActions.changeActiveStepIndex(2));
  }, [dispatch]);

  const columns = useMemo<ColumnsType<Warehouse>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'name',
        render: (_, record) => record.name,
      },
      {
        title: t('Address'),
        key: 'address',
        render: (_, record) => `${record.address} ${record.city.name} ${record.zip || ''}`,
      },
      {
        title: t('Sizes'),
        key: 'sizes',
        render: (_, record) => {
          const { minSquare, maxSquare } = getMinMaxSquare(record);
          const hasSizes = Boolean(minSquare && maxSquare);

          return hasSizes ? `${minSquare} m² - ${maxSquare} m²` : '-';
        },
      },
    ],
    [t],
  );

  const tableActions = useMemo((): Array<ServerTableActions<Box>> => {
    const tableActions: Array<ServerTableActions<Box>> = [
      {
        name: t('Filters'),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ];

    const additionalTableActions: Array<ServerTableActions<Box>> = [
      {
        name: t('Next'),
        onClick: goToChoosePermissionStep,
      },
    ];

    const resultedTableActions = !showNextButtonWithoutWarehouses ? tableActions : [...tableActions, ...additionalTableActions];

    return resultedTableActions;
  }, [goToChoosePermissionStep, setFiltersOpened, showNextButtonWithoutWarehouses, t]);

  const selectWarehouses = (warehouseIds: string[]): void => {
    dispatch(createUserModalActions.setSelectedWarehouses(warehouseIds));
  };

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Warehouse>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        selectWarehouses(selectedElements.map(({ warehouseId }) => warehouseId));
        goToChoosePermissionStep();
      },
    },
  ];

  return (
    <div>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedWarehousesQuery}
        rowKey="warehouseId"
        search
        title={t('Choose warehouse')}
        selectionType="checkbox"
        rowSelectionActions={rowSelectionActions}
        onRowSelect={(record, selected, selectedRows) => {
          setShowNextButtonWithoutWarehouses(!selectedRows.length);
        }}
        onAllRowsSelect={(selected, selectedRows) => {
          setShowNextButtonWithoutWarehouses(!selectedRows.length);
        }}
        tableActions={tableActions}
        defaultFilters={{
          ...transformWarehouseListFilters(appliedFilters),
        }}
        tags={tags}
      />
      <WarehouseListFilter />
    </div>
  );
});
