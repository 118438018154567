import React, { FC, memo } from 'react';
import { Modal } from 'shared/ui/Modal';
import { useRenderHTMLModal } from '../utils/hooks/useRenderHTMLModal';

export const RenderHTMLModal: FC = memo(() => {
  const { isOpened, htmlString, closeRenderHTMLModal } = useRenderHTMLModal();

  return (
    <Modal width={1100} isOpen={isOpened} onClose={closeRenderHTMLModal}>
      <iframe className="mt-8 w-full h-[calc(100vh-100px)]" srcDoc={htmlString || undefined} />
    </Modal>
  );
});
