import React, { FC, ReactElement, memo, useCallback, useMemo, useState } from 'react';
import { Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { Select } from 'shared/ui/Select';
import { Checkbox } from 'shared/ui/Checkbox';
import { DatePicker } from 'shared/ui/DatePicker';
import { useCloseContractMutation, CloseContractParams, Contract } from 'entities/Contract';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import dayjs, { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface CloseContractProps {
  contract: Contract;
  action: ReactElement;
}

export const CloseContract: FC<CloseContractProps> = memo((props) => {
  const { contract, action } = props;

  const [form] = Form.useForm();

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.CLOSE_CONTRACT_EXTENDED);

  const { t } = useAppTranslation(['contracts', 'common']);

  const { isDesktop } = useScreenBreakpoints();

  const [closeContract, { isLoading }] = useCloseContractMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const refundOptions = useMemo(
    () => [
      { label: t('Default'), value: 'default' },
      { label: t('Refund all'), value: 'refund-all' },
      { label: t('Disable refunds'), value: 'no-refunds' },
    ],
    [t],
  );

  const defaultValues = { refundOptions: 'default', leaveDate: dayjs().startOf('day') };

  const disableDate = useCallback(
    (currentDate: Dayjs): boolean => {
      return !isForbidden ? currentDate.isBefore(contract.createdAt, 'day') : currentDate.isBefore(contract.initialEndDate, 'day');
    },
    [contract.createdAt, contract.initialEndDate, isForbidden],
  );

  const handleCloseContract = useCallback(
    async (data: CloseContractParams): Promise<void> => {
      try {
        if (contract) {
          await closeContract({
            contractId: contract.contractId,
            params: {
              ...data,
              leaveDate: getServerDateFormat(data.leaveDate),
            },
          }).unwrap();
          showNotification('info', t('Success'), t('Contract closed successfully', { ns: 'common' }));
          closeModal();
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when closing contract'));
        console.log('error', error);
      } finally {
        closeModal();
      }
    },
    [contract, closeContract, t, closeModal],
  );

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal width={isDesktop ? 400 : 350} isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" initialValues={defaultValues} onFinish={handleCloseContract} className="flex flex-col">
          <div className="font-semibold text-xl mb-3">{t('Close contract options')}</div>
          <div className="text-primaryLight font-normal">{t('Are you sure you want to close this contract?')}</div>

          <div className="mt-5">
            <Form.Item
              className="w-full"
              label={t('Close date')}
              name="leaveDate"
              rules={[
                {
                  required: true,
                  message: t('Please enter leave date!'),
                },
              ]}
            >
              <DatePicker
                className="h-[52px] w-full"
                format={CLIENT_DATE_FORMAT}
                placeholder={t('Enter leave date')}
                disabledDate={disableDate}
                inputReadOnly
                defaultPickerValue={dayjs().startOf('day')}
              />
            </Form.Item>
          </div>

          <div className="mb-5">
            <Form.Item label={t('Refund options')} name="refundOptions" className="flex-1">
              <Select options={refundOptions} placeholder={t('Please, select warehouse')} bordered disabled={isForbidden} />
            </Form.Item>
            <Form.Item name="issueUnpaidInvoices" valuePropName="checked">
              <Checkbox defaultChecked name="issueUnpaidInvoices" disabled={isForbidden}>
                {t('Issue unpaid invoices')}
              </Checkbox>
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading}>
              {t('Close contract', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
