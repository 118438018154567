import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenderHTMLSchema } from '../types';

const initialState: RenderHTMLSchema = {
  htmlString: null,
};

const renderHTMLSlice = createSlice({
  name: 'renderHTML',
  initialState,
  reducers: {
    openModal: (state: RenderHTMLSchema, action: PayloadAction<Nullable<string>>) => {
      state.htmlString = action.payload;
    },
    closeModal: (state: RenderHTMLSchema) => {
      state.htmlString = null;
    },
  },
});

export const { actions: renderHTMLActions, reducer: renderHTMLReducer } = renderHTMLSlice;
