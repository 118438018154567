import React, { FC, memo, ReactNode } from 'react';
import { Modal } from 'shared/ui/Modal';
import { useRowFullInfo } from '../utils/hooks/useRowFullInfo';
import { t } from 'i18next';
import { Divider } from 'antd';

export const TableRowFullInfoModal: FC = memo(() => {
  const { isOpenedRowFullInfo, rowFullInfo, closeFullInfoModal } = useRowFullInfo();

  const renderData = (): ReactNode[] => {
    const rows: ReactNode[] = [];

    if (!rowFullInfo) {
      return [];
    }

    rowFullInfo.rows.forEach((row) => {
      rows.push(
        <div className="flex justify-between py-1 items-center">
          <div className="font-normal">{row.label}</div>
          <div>{row.value || '-'}</div>
        </div>,
      );
    });

    return rows;
  };

  return (
    <Modal isOpen={isOpenedRowFullInfo} onClose={closeFullInfoModal} className="max-w-[320px]">
      <div className="px-4">
        <div className="flex items-center gap-4">
          <div className="mt-5 text-lg font-semibold">{rowFullInfo?.title}</div>
          <div className="mt-5 text-error"> — {rowFullInfo?.status}</div>
        </div>

        <div>
          <div className="mt-7 text-accent">{t('Box Details')}</div>
          <div className="mt-1">{renderData()}</div>
        </div>

        <Divider className="mb-4 mt-2" />

        <div>
          <div className="text-accent">{t('Note')}</div>
          <div className="mt-2">{rowFullInfo?.note}</div>
        </div>
      </div>
    </Modal>
  );
});
