import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useCallback } from 'react';
import { getOpenedState } from '../../model/selectors/getOpenedState';
import { getRowFullInfo } from '../../model/selectors/getRowFullInfo';
import { tableRowFullInfoModalActions } from '../../model/slice/tableRowFullInfoModalSlice';
import { FullInfoRow, TableRowFullInfo } from '../../model/types';

interface HookApi {
  isOpenedRowFullInfo: boolean;
  rowFullInfo: Nullable<TableRowFullInfo>;
  openFullInfoModal: (title: string, status: string, data: FullInfoRow[], note?: string) => void;
  closeFullInfoModal: () => void;
}

export const useRowFullInfo = (): HookApi => {
  const dispatch = useAppDispatch();

  const isOpenedRowFullInfo = useAppSelector(getOpenedState);
  const rowFullInfo = useAppSelector(getRowFullInfo);

  const openFullInfoModal = useCallback(
    (title: string, status: string, data: FullInfoRow[], note?: string): void => {
      dispatch(tableRowFullInfoModalActions.openModal({ title, status, rows: data, note }));
    },
    [dispatch],
  );

  const closeFullInfoModal = useCallback((): void => {
    dispatch(tableRowFullInfoModalActions.closeModal());
  }, [dispatch]);

  return {
    isOpenedRowFullInfo,
    rowFullInfo,
    openFullInfoModal,
    closeFullInfoModal,
  };
};
