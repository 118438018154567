import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import ChristmasPromotionBackground from 'shared/assets/images/ChristmasPromotionBackground.png';
import ChristmasPromotionBackgroundMobile from 'shared/assets/images/ChristmasPromotionBackgroundMobile.png';
import { ReactComponent as ChristmasIcon } from 'shared/assets/icons/ChristmasIcon.svg';
import { getGlobalSettings } from 'app/appState';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

export const ChristmasPromotionContent: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });
  const { closeModal } = useSpecialPromotionModal();

  const globalSettings = useAppSelector(getGlobalSettings);

  const { isDesktop } = useScreenBreakpoints();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  return (
    <div>
      <img
        className="w-full"
        src={isDesktop ? ChristmasPromotionBackground : ChristmasPromotionBackgroundMobile}
        alt="Christmas promotion"
      />
      <div className="flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-20 desktop:p-24">
        <ChristmasIcon className="mb-3.5 w-[239px] desktop:mb-4 desktop:w-[485px]" />
        <div className="text-[12px] font-bold text-[#C11B2A] text-center mb-3.5 desktop:text-4xl">
          <Trans
            t={t}
            i18nKey="Christmas gift from <0>{{companyName}}</0>:"
            components={[<span key="0" className="uppercase text-[12px] font-bold text-[#C11B2A] desktop:text-4xl" />]}
            values={{ companyName: globalSettings?.companyName }}
          />
        </div>
        <div className="text-[14px] text-center font-normal text-[#C11B2A] [-webkit-text-stroke:0.5px] font-doodle mb-3.5 border border-[#C11B2A] rounded-xl shadow-[0_2px_12px_2px_#C11B2A] p-3 desktop:text-4xl desktop:[-webkit-text-stroke:1px] desktop:mb-7">
          {t('12% discount for 12 months!')}
        </div>
        <div className="text-center max-w-[483px] mb-3.5 text-[12px] font-normal desktop:text-[20px] desktop:mb-4">
          {t(
            'This year we are giving you a present before the holidays! Rent a storage unit in December and get a 12% discount for a full 12 months - with no long-term commitment. Minimum term: just 1 month!',
          )}
        </div>
        <Button
          containerClassName="min-w-[155px] max-w-[351px]"
          className="!bg-[#C11B2A]"
          fontSize={isDesktop ? 'medium' : 'small'}
          onClick={goToBooking}
        >
          {t('Book now')}
        </Button>
      </div>
    </div>
  );
});
