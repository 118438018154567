import dayjs from 'dayjs';
import { GOOGLE_CLICK_ID_NAME, GOOGLE_CLICK_ID_EXPIRY_DAYS } from '../constants';
import { getGoogleClickIdFromURL } from './getGoogleClickIdFromURL';

export const setGoogleClickIdToCookie = (): void => {
  const googleClickId = getGoogleClickIdFromURL();

  if (googleClickId) {
    const expireDate = dayjs().add(GOOGLE_CLICK_ID_EXPIRY_DAYS, 'day').toDate();

    document.cookie = `${GOOGLE_CLICK_ID_NAME}=${googleClickId}; expires=${expireDate.toUTCString()}; path=/; SameSite=Lax;`;
  }
};
