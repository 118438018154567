import { Note } from 'entities/Note';
import { Warehouse } from 'entities/Warehouse';

export interface UserSchema {
  userData: Nullable<User>;
  credentials: Nullable<AuthResponse>;
}

export interface User {
  userId: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  dateOfBirth?: Nullable<string>;
  street: string;
  house: string;
  zip: string;
  apartment: string;
  email: string;
  country: string;
  city: string;
  language: string;
  identificationNumber?: Nullable<string>;
  additionalEmail?: Nullable<string>;
  bankingRequisites?: Nullable<string>;
  permissions?: UserPermissions[];
  accountType: UserAccountType;
  role: UserRoles;
  warehouses: Warehouse[];
  createdNotes?: Note[];
  createdAt: string;
  note?: string;
  balance: number;
}

export interface UpdateUserParams extends User {
  password?: string;
}

export enum UserRoles {
  SUPERUSER = 'superuser',
  MANAGER = 'manager',
  CUSTOMER = 'customer',
  STAFF = 'staff',
}

export enum UserAccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export interface RegisterUserParams {
  firstName?: string;
  lastName?: string;
  company?: string;
  email: string;
  phone?: string;
  country?: string;
  dateOfBirth?: string;
  street?: string;
  house?: string;
  zip?: string;
  apartment?: string;
  city: string;
  language: string;
  identificationNumber?: string;
  accountType: UserAccountType;
  password: string;
}

export interface CompleteRegisterUserParams {
  firstName: string;
  lastName: string;
  company?: string;
  phone: string;
  country: string;
  dateOfBirth: string;
  street: string;
  house: string;
  zip: string;
  apartment?: string;
  city: string;
  language: string;
  identificationNumber?: string;
  accountType: UserAccountType;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  email?: string;
}

export interface JWTUser {
  userId: string;
  role: string;
  assignedWarehouseIds: string[];
  email: string;
}

export interface LoginData {
  email: string;
  password: string;
}

export interface UserForListing extends User {
  contractDebt: number;
  balance: number;
}

export type ChangeUserAddressParams = Pick<UpdateUserParams, 'country' | 'city' | 'street' | 'zip' | 'house' | 'apartment'>;

export type ChangeUserDetailsParams = Pick<
  UpdateUserParams,
  'additionalEmail' | 'language' | 'dateOfBirth' | 'identificationNumber' | 'bankingRequisites'
>;

export type ChangeUserPasswordParams = Pick<UpdateUserParams, 'password'>;

export interface CreateUserParams {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  country: string;
  phone: string;
  dateOfBirth?: string;
  street: string;
  house: string;
  zip: string;
  apartment?: string;
  city: string;
  language: string;
  identificationNumber?: Nullable<string>;
  accountType: UserAccountType;
  note?: string;
  role: UserRoles;
  assignedWarehouseIds?: string[];
  permissions?: UserPermissions[];
}

export enum UserPermissions {
  CREATE_UNITS = 'create_units',
  EDIT_UNITS = 'edit_units',
  DELETE_UNITS = 'delete_units',
  REFUND_DEPOSITS = 'refund_deposits',
  REFUND_PAYMENTS = 'refund_payments',
  MARK_AS_PAID_INVOICE = 'mark_as_paid_invoice',
  MARK_AS_REFUNDED = 'mark_as_refunded',
  CREDIT_INVOICE = 'credit_invoice',
  CREDIT_PAYMENT = 'credit_payment',
  REPLENISH_BALANCE = 'replenish_balance',
  REDUCE_BALANCE = 'reduce_balance',
  CHARGE_INVOICE = 'charge_invoice',
  CLOSE_CONTRACT_EXTENDED = 'close_contract_extended',
  MANAGE_AUTOCHARGES = 'manage_autocharges',
}
