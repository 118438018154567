import { TFunction } from 'i18next';
import { BoxForListing } from 'entities/Box';
import { FullInfoRow } from 'features/TableRowFullInfoModal';
import { getClientDateFormat, getClientDateWithTimeFormat } from 'shared/utils/helpers/getDateFormat';

interface ReturnType {
  title: string;
  status: string;
  rows: FullInfoRow[];
  note?: string;
}

export const boxFullInfoMapper = (box: BoxForListing, currencySymbol: string, t: TFunction): ReturnType => {
  // @ts-expect-error Partial not working
  const readable: Record<keyof Partial<BoxForListing>, { label: string; value?: string | number }> = {
    dailyRate: { label: t('Daily rate'), value: box.dailyRate ? `${box.dailyRate} ${currencySymbol}` : t('Not specified') },
    weekRate: { label: t('Week rate'), value: box.weekRate ? `${box.weekRate} ${currencySymbol}` : t('Not specified') },
    monthRate: { label: t('Month rate'), value: box.monthRate ? `${box.monthRate} ${currencySymbol}` : t('Not specified') },
    userEmail: { label: t('Customer'), value: box.userEmail },
    contractNumber: { label: t('Contract'), value: box.contractNumber },
    reservationNumber: { label: t('Reservation'), value: box.reservationNumber },
    lastOpened: { label: t('Last opened'), value: getClientDateWithTimeFormat(box.lastOpened) },
    chargedTo: { label: t('Charged to'), value: getClientDateFormat(box.chargedTo) },
    isContractPaid: { label: t('Is paid'), value: box.contractNumber && t(box.isContractPaid ? 'Contract is paid' : 'Contract is unpaid') },
    expirationDate: { label: t('Expiration date'), value: getClientDateFormat(box.expirationDate) },
  };

  const rows: FullInfoRow[] = [];

  Object.keys(box).forEach((key) => {
    if (!readable[key as keyof BoxForListing]) {
      return;
    }

    const label = readable[key as keyof BoxForListing]?.label;
    const value = readable[key as keyof BoxForListing]?.value;

    rows.push({ label, value });
  });

  return {
    title: t('Box: {{boxName}}', { boxName: box.name }),
    status: t(box.isActive ? 'Enabled' : 'Disabled'),
    note: box?.note,
    rows,
  };
};
