import React, { memo, MouseEvent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

type ButtonTypes = 'submit' | 'reset' | 'button';
export type ButtonThemes = 'primary' | 'secondary' | 'clear' | 'danger' | 'outlined' | 'warn' | 'success' | 'dark';
export type ButtonIconPositions = 'prev' | 'post';
export type ButtonSize = 'small' | 'medium' | 'large';
export type FontSize = 'small' | 'medium' | 'large';
export type FontColor = 'white' | 'accent' | 'primary';

interface ButtonProps extends PropsWithChildren {
  theme?: ButtonThemes;
  type?: ButtonTypes;
  icon?: ReactElement;
  iconPosition?: ButtonIconPositions;
  onClick?: () => void;
  containerClassName?: string;
  className?: string;
  size?: ButtonSize;
  fontSize?: FontSize;
  fontColor?: FontColor;
  isLoading?: boolean;
  isDisabled?: boolean;
  description?: string | ReactNode;
  stopPropagation?: boolean;
  id?: string;
}

export const Button: React.FC<ButtonProps> = memo((props) => {
  const {
    children,
    type = 'button',
    theme = 'primary',
    icon,
    iconPosition = 'post',
    onClick,
    containerClassName,
    className,
    size = 'small',
    fontSize = 'medium',
    fontColor,
    isLoading,
    isDisabled,
    description,
    stopPropagation = true,
    id,
  } = props;

  const commonClasses =
    'rounded-xl flex items-center justify-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed ease-linear duration-200';

  const themesMap: Record<ButtonThemes, string> = {
    primary: `bg-accent border-0 text-${fontColor || 'white'} px-4`,
    secondary: 'bg-white border border-secondaryAccent px-4',
    clear: `bg-transparent border-0 text-${fontColor || 'accent'} w-max !p-0`,
    danger: `bg-error border border-error text-${fontColor || 'white'} py-[10px] px-4`,
    warn: `bg-warn border border-warn text-${fontColor || 'white'} py-[10px] px-4`,
    success: `bg-success border border-success text-${fontColor || 'white'} py-[10px] px-4`,
    outlined: `bg-transparent border border-secondaryAccent text-${fontColor || 'primary'} p-4`,
    dark: `bg-black border-0 text-${fontColor || 'white'} px-4`,
  };

  const sizesMap: Record<ButtonSize, string> = {
    small: 'py-[9.5px]',
    medium: 'py-[13.5px]',
    large: 'py-5',
  };

  const fontSizesMap: Record<FontSize, string> = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    if (!isDisabled || !isLoading) {
      stopPropagation && e.stopPropagation();
      onClick?.();
    }
  };

  const buttonLoaderClass = classNames({
    'animate-spin h-5 w-5 border-t-4 border-solid rounded-full mr-3': children,
    'animate-spin h-4 w-4 border-t-4 border-solid rounded-full mr-0': isLoading && !children,
  });

  const notShrinkIcon = icon
    ? React.cloneElement(icon, { className: `${icon.props.className ? `${icon.props.className} ` : ''}shrink-0` })
    : undefined;

  return (
    <div className={classNames('flex flex-col', containerClassName)}>
      <button
        id={id}
        className={classNames(commonClasses, themesMap[theme], sizesMap[size], className)}
        type={type}
        disabled={isDisabled || isLoading}
        onClick={handleClick}
      >
        {icon && iconPosition === 'prev' && !isLoading && notShrinkIcon}
        {isLoading && <span className={buttonLoaderClass} />}
        {children && <span className={classNames(fontSizesMap[fontSize], { 'font-medium': theme === 'clear' })}>{children}</span>}
        {icon && iconPosition === 'post' && !isLoading && notShrinkIcon}
      </button>
      {description && <div className="text-center self-center font-normal text-xs mt-2">{description}</div>}
    </div>
  );
});
