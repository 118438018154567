import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Email, EmailType } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  to?: Nullable<string>;
  emailType?: EmailType | EmailType[];
}

export const getSavedEmailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSavedEmails: builder.query<Pagination<Email>, Params | void>({
      query: (params) => ({
        url: '/emails/saved',
        params: params || undefined,
      }),
    }),
  }),
});

export const { useGetSavedEmailsQuery } = getSavedEmailsApi;
