import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getOfferEmail } from '../../model/selectors/getOfferEmail';
import { offerHistoryModalActions } from '../../model/slice/offerHistoryModalSlice';

interface HookApi {
  isOpened: boolean;
  offerEmail: Nullable<string>;
  openOfferHistoryModal: (email: string) => void;
  closeOfferHistoryModal: () => void;
}

export const useOfferHistoryModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const offerEmail = useAppSelector(getOfferEmail);

  const openOfferHistoryModal = useCallback(
    (email: string) => {
      dispatch(offerHistoryModalActions.openModal(email));
    },
    [dispatch],
  );

  const closeOfferHistoryModal = useCallback(() => {
    dispatch(offerHistoryModalActions.closeModal());
  }, [dispatch]);

  return {
    isOpened: Boolean(offerEmail),
    offerEmail,
    openOfferHistoryModal,
    closeOfferHistoryModal,
  };
};
