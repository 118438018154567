import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { useSpecialPromotionModal } from 'features/SpecialPromotion';
import { Button } from 'shared/ui/Button';
import BlackFridayBanner from 'shared/assets/images/BlackFridayBanner.png';
import { useGetCurrencySymbol } from 'app/appState';
import { Trans } from 'react-i18next';

export const BlackFridayPromotionContent: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const navigate = useNavigate();

  const { data } = useGetRentOptionsQuery({ isSpecialPromotion: true });
  const currencySymbol = useGetCurrencySymbol();
  const { closeModal } = useSpecialPromotionModal();

  const goToBooking = useCallback(() => {
    navigate(data?.length ? `${AppRoutes.BOOKING}?rentOptionId=${data[0].rentOptionId}` : AppRoutes.BOOKING);
    closeModal();
  }, [closeModal, data, navigate]);

  return (
    <div>
      <img src={BlackFridayBanner} alt="Black friday promotion" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[520px] w-full bg-white p-7 text-center shadow-xl rounded-xl">
        <div className="mb-3 flex justify-end">
          <Button theme="clear" className="text-black" onClick={closeModal}>
            {t('Close')}
          </Button>
        </div>
        <div className="mb-2.5 font-black text-4xl uppercase">{t('Black Friday sale')}</div>
        <div className="font-normal text-2xl">
          <Trans
            t={t}
            i18nKey="Only <0>11</0> days until December <0>1st:</0>"
            components={[<span key="0" className="font-bold text-2xl" />]}
          />
        </div>
        <div className="mb-8 font-normal text-2xl">
          <Trans
            t={t}
            i18nKey="Each box for <0>11 {{currencySymbol}}</0> in the first month!"
            components={[<span key="0" className="font-bold text-2xl" />]}
            values={{ currencySymbol }}
          />
        </div>
        <Button theme="dark" fontSize="large" onClick={goToBooking}>
          {t('Shop now')}
        </Button>
        <div className="mb-4 mt-2 font-normal text-2xl">{t('and secure a special price!')}</div>
      </div>
    </div>
  );
});
