import { AppLanguage } from 'app/config/i18Config/types';
import { Warehouse } from 'entities/Warehouse';

export interface LeadData {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  email?: Nullable<string>;
  language?: Nullable<AppLanguage>;
  phone?: Nullable<string>;
  note?: Nullable<string>;
  warehouseId?: Nullable<string>;
  square?: Nullable<number>;
  userId?: Nullable<string>;
  userCreationDate?: Nullable<string>;
  isThirdPartyAuthorized?: boolean;
  isAutoMailingEnabled?: boolean;
  googleClickId?: Nullable<string>;
  googleClickIdDate?: Nullable<string>;
}

export interface Lead {
  leadId: string;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  language?: Nullable<AppLanguage>;
  note?: Nullable<string>;
  warehouseId?: Nullable<string>;
  warehouse?: Nullable<Warehouse>;
  contractId?: Nullable<string>;
  userId?: Nullable<string>;
  reservationId?: Nullable<string>;
  square?: Nullable<number>;
  isContractPaid?: boolean;
  isLeft?: boolean;
  isAutoMailingEnabled?: boolean;
  userCreationDate?: Nullable<string>;
  reservationDate?: Nullable<string>;
  contractCreationDate?: Nullable<string>;
  contractEndDate?: Nullable<string>;
  firstPaymentDate?: Nullable<string>;
  createdAt?: string;
  updatedAt?: string;
}

export interface SendOfferParams {
  email: string;
  warehouseId: string;
  boxId: string[];
  language?: AppLanguage;
}

export enum LeadStatus {
  ACTIVE = 'active request',
  RESERVATION = 'reservation',
  PAID = 'paid',
  NOT_PAID = 'not paid',
  END = 'end',
  LEFT = 'left',
}
