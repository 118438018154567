import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { useCreateUser } from 'features/CreateUser';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { getUserData } from '../model/selectors/getUserData';
import { MultipleSelect } from 'shared/ui/MultipleSelect';
import { permissionPresets, UserPermissions } from 'entities/User';
import { Form } from 'antd';
import { Button } from 'shared/ui/Button';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { getSelectedPermissions } from '../model/selectors/getSelectedPermissions';
import { getSelectedWarehouses } from '../model/selectors/getSelectedWarehouses';

interface ChoosePermissionStepProps {
  onSuccess: () => void;
}

export const ChoosePermissionStep: FC<ChoosePermissionStepProps> = memo((props) => {
  const { onSuccess } = props;

  const { t } = useAppTranslation(['profile', 'user']);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const { createUser, isLoading, isOpenedConfirm, closeConfirmModal, openConfirmModal } = useCreateUser(onSuccess);

  const userData = useAppSelector(getUserData);
  const assignedWarehouseIds = useAppSelector(getSelectedWarehouses);
  const permissions = useAppSelector(getSelectedPermissions);

  const handleCreateUser = useCallback((): void => {
    if (userData) {
      createUser({ ...userData, assignedWarehouseIds, permissions });
    }
  }, [assignedWarehouseIds, createUser, permissions, userData]);

  const handleSubmit = useCallback(
    (data: { permissions: UserPermissions[] }): void => {
      dispatch(createUserModalActions.setSelectedPermissions(data.permissions));

      openConfirmModal();
    },
    [dispatch, openConfirmModal],
  );

  const permissionGroups: Record<string, UserPermissions[]> = useMemo(() => {
    return permissionPresets;
  }, []);

  const permissionOptions = [
    { label: t('Standard package'), value: 'standard' },
    { label: t('Extended package'), value: 'extended' },
    ...Object.values(UserPermissions).map((permission) => ({
      label: t(permission),
      value: permission,
    })),
  ];

  const changePermissions = useCallback(
    (selectedOptions: string[]) => {
      const permissions = selectedOptions.filter((value) => !permissionGroups[value]);

      const expandedPermissions = selectedOptions.flatMap((value) => permissionGroups[value] || value);

      const uniquePermissions = Array.from(new Set([...permissions, ...expandedPermissions]));

      form.setFieldsValue({ permissions: uniquePermissions });
    },
    [form, permissionGroups],
  );

  return (
    <div>
      <div className="font-semibold text-2xl mb-4">{t('Choose Permissions')}</div>
      <Form className="space-y-2" name="permissionStep" onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item className="w-full" name="permissions" label={t('Add permisions')}>
          <MultipleSelect
            placeholder={t('Please, add permissions')}
            bordered
            options={permissionOptions}
            allowClear
            onChange={changePermissions}
          />
        </Form.Item>
        <div className="flex justify-end mt-2">
          <Button type="submit">{t('Create', { ns: 'user' })}</Button>
        </div>
      </Form>
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create user', { ns: 'user' })}
        description={t('Are you sure you want to create user?', { ns: 'user' })}
        isLoading={isLoading}
        onOk={handleCreateUser}
        onCancel={closeConfirmModal}
      />
    </div>
  );
});
