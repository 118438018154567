import { NoteType } from 'entities/Note';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface NoteListFilters {
  warehouseId?: string;
  deadlineDateFrom?: string;
  deadlineDateTo?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  noteType?: NoteType;
  assignedUsers?: string | string[];
}

export const transformNotesListFilters = ({
  selectedWarehouseId,
  noteType,
  deadlineDateFrom,
  deadlineDateTo,
  creationDateFrom,
  creationDateTo,
  assignedUsers,
}: TableFilterType): NoteListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    deadlineDateFrom: getFilterServerFormatDate(deadlineDateFrom?.value),
    deadlineDateTo: getFilterServerFormatDate(deadlineDateTo?.value),
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    noteType: noteType?.value || undefined,
    assignedUsers: assignedUsers?.value || undefined,
  };
};
