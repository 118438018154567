import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getHtmlString } from '../../model/selectors/getHtmlString';
import { renderHTMLActions } from '../../model/slice/renderHTMLSlice';

interface HookApi {
  isOpened: boolean;
  htmlString: Nullable<string>;
  openRenderHTMLModal: (htmlString: string) => void;
  closeRenderHTMLModal: () => void;
}

export const useRenderHTMLModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const htmlString = useAppSelector(getHtmlString);

  const openRenderHTMLModal = useCallback(
    (htmlString: string) => {
      dispatch(renderHTMLActions.openModal(htmlString));
    },
    [dispatch],
  );

  const closeRenderHTMLModal = useCallback(() => {
    dispatch(renderHTMLActions.closeModal());
  }, [dispatch]);

  return {
    isOpened: Boolean(htmlString),
    htmlString,
    openRenderHTMLModal,
    closeRenderHTMLModal,
  };
};
