import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Modal } from 'shared/ui/Modal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { Invoice, InvoiceItemType, useChargeInvoiceMutation } from 'entities/Invoice';
import { Button } from 'shared/ui/Button';
import { WarnTag } from 'shared/ui/WarnTag';
import { useGetCurrencySymbol } from 'app/appState';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';

interface ChargeModalProps {
  invoice: Invoice;
  isOpen: boolean;
  onClose: () => void;
}

export const ChargeModal: FC<ChargeModalProps> = memo((props) => {
  const { invoice, isOpen, onClose } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const [chargeInvoice, { isLoading }] = useChargeInvoiceMutation();

  const isForbidden = useCheckPermission(UserPermissions.CHARGE_INVOICE);

  const currencySymbol = useGetCurrencySymbol();

  const handleChargeInvoice = useCallback(async (): Promise<void> => {
    try {
      if (invoice) {
        await chargeInvoice(invoice.invoiceId).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Send charge request'));
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error', { ns: 'common' }), t('Error while sending request'));
    } finally {
      onClose();
    }
  }, [chargeInvoice, invoice, onClose, t]);

  const actions = useCallback((): JSX.Element => {
    return (
      <div className="flex space-x-3 justify-end">
        <Button theme="secondary" onClick={onClose}>
          {t('Cancel', { ns: 'common' })}
        </Button>
        <Button isLoading={isLoading} onClick={handleChargeInvoice} isDisabled={isForbidden}>
          {t('Charge {{amount}} {{currency}}', { ns: 'common', amount: invoice.debt, currency: invoice.currency })}
        </Button>
      </div>
    );
  }, [onClose, t, isLoading, handleChargeInvoice, isForbidden, invoice.debt, invoice.currency]);

  return (
    <>
      <Modal className="max-w-[400px]" isOpen={isOpen} onClose={onClose} footer={actions()}>
        <div>
          <div className="font-semibold text-xl mb-5">{t('Charge Invoice {{number}}', { number: invoice?.documentNumber })}</div>
          <WarnTag textSize="medium">{t('This Invoice will be charged automatically')}</WarnTag>
          <div className="my-5">
            {invoice.invoiceItems.map((item) => (
              <div key={item.invoiceItemId}>
                {item.invoiceItemType && (
                  <div className="mt-2">
                    {t(item.invoiceItemType)} {item.invoiceItemType === InvoiceItemType.BOX ? `#${item?.contractItem?.box?.name}` : null}
                  </div>
                )}
                <BulletsTable
                  theme="clear"
                  rows={[
                    {
                      label: t('Payment'),
                      value: `${roundNumber(item.initialPrice)} ${currencySymbol}`,
                      emptyValueSymbol: `0 ${currencySymbol}`,
                    },
                    {
                      label: t('Discount'),
                      value: `${item.discountPercent}%`,
                      emptyValueSymbol: '0%',
                    },
                    {
                      label: t('VAT'),
                      value: `${item.vatRatePercent}%`,
                      emptyValueSymbol: '0%',
                    },
                    {
                      label: t('Subtotal'),
                      value: `${roundNumber(item.totalPrice)} ${currencySymbol}`,
                      emptyValueSymbol: `0 ${currencySymbol}`,
                    },
                  ]}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
});
