export interface Email {
  emailId: string;
  to: string;
  htmlTemplate: string;
  emailType: EmailType;
  base64Attachment?: string;
  createdAt: string;
  updatedAt: string;
}

export enum EmailType {
  CREATE_ACCOUNT = 'create_account',
  RESET_PASSWORD = 'reset_password',
  INDEX = 'index',
  NEW_CUSTOMER = 'new_customer',
  NEW_LEAD = 'new_lead',
  SEND_OFFER = 'send_offer',
  REQUEST_FORM = 'request_form',
  RESERVE_BOX = 'reserve_box',
  CLIENT_RESERVATION = 'client_reservation',
  CLIENT_СANCEL_RESERVATION = 'client_cancel_reservation',
  CANCEL_RESERVATION = 'cancel_reservation',
  CLOSE_CONTRACT = 'close_contract',
  PAYMENT_CONFIRMATION = 'payment_confirmation',
  CLIENT_BOOKING = 'client_booking',
  BOOK_BOX = 'book_box',
  CLIENT_NOTICE_LEAVE_DATE = 'client_notice_leave_date',
  NEW_INVOICE = 'new_invoice',
  FIRST_LATE_CHARGE_NOTIFICATION = 'first_late_charge_notification',
  SECOND_LATE_CHARGE_NOTIFICATION = 'second_late_charge_notification',
  NEW_LATE_CHARGE = 'new_late_charge',
  FINAL_LATE_CHARGE = 'final_late_charge',
  NOTIFY_LEAVE_DATE = 'notify_leave_date',
  TERMINATION_NOTIFICATION = 'termination_notification',
  LOGIN_LINK = 'login_link',
  FIRST_LEAD_REMINDER = 'first_lead_reminder',
  SECOND_LEAD_REMINDER = 'second_lead_reminder',
  THIRD_LEAD_REMINDER = 'third_lead_reminder',
}
