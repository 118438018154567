import React, { ChangeEvent, FC, memo } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';

const { TextArea: AntdTextArea } = Input;

interface TextAreaProps {
  value?: string | null;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  bordered?: boolean;
  rows?: number;
}

export const TextArea: FC<TextAreaProps> = memo((props) => {
  const { value, placeholder, className, onChange, rows, bordered = false } = props;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange?.(e.target.value);
  };

  return (
    <AntdTextArea
      className={classNames('bg-white text-lg placeholder:text-lg placeholder:font-medium', className)}
      variant={bordered ? 'outlined' : 'borderless'}
      size="large"
      rows={rows}
      placeholder={placeholder}
      value={value || undefined}
      onChange={handleChange}
    />
  );
});
