import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { InputNumber } from 'shared/ui/InputNumber';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';
import { showNotification } from 'app/providers/NotificationsProvider';
import { validateLocalizedJSON } from 'shared/utils/helpers/JSONLocalization';
import { AmountType, DrawerMode, LateChargeSettingFormFields } from '../model/types';
import { LateChargeSetting, useCreateLateChargeSettingMutation, useUpdateLateChargeSettingMutation } from 'entities/LateChargeSetting';
import { useGetCurrencySymbol } from 'app/appState';
import { MultipleSelect } from 'shared/ui/MultipleSelect';
import { Checkbox } from 'shared/ui/Checkbox';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface LateChargeSettingFormProps {
  lateChargeSetting?: Nullable<LateChargeSetting>;
  mode: Nullable<DrawerMode>;
  handleClose: () => void;
}

export const LateChargeSettingForm: FC<LateChargeSettingFormProps> = memo((props) => {
  const { lateChargeSetting, mode, handleClose } = props;
  const { t } = useAppTranslation(['boxes', 'common']);

  const [amountType, setAmountType] = useState<string>(AmountType.NUMBER);

  const isForbiddenEdit = useCheckPermission(UserPermissions.EDIT_UNITS);

  const [form] = Form.useForm();

  const [createLateChargeSetting, { isLoading: isCreationLoading }] = useCreateLateChargeSettingMutation();
  const [updateLateChargeSetting, { isLoading: isUpdationLoading }] = useUpdateLateChargeSettingMutation();

  const isLoading = isUpdationLoading || isCreationLoading;

  const currencySymbol = useGetCurrencySymbol();

  const isCreateMode = mode === DrawerMode.LATE_CHARGE_SETTING_CREATE;

  const amountTypeOptions = [
    {
      label: t('Percentage'),
      value: AmountType.PERCENTAGE,
    },
    {
      label: t('Number'),
      value: AmountType.NUMBER,
    },
  ];

  const notificationDaysOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 30; i++) {
      options.push({ value: i.toString(), label: i.toString() });
    }
    return options;
  }, []);

  const changeAmountType = useCallback((amountType: string): void => {
    setAmountType(amountType);
  }, []);

  useEffect(() => {
    if (lateChargeSetting) {
      if (lateChargeSetting.amount.includes('%')) {
        form.setFieldsValue({
          ...lateChargeSetting,
          amount: lateChargeSetting.amount.replace('%', ''),
        });

        setAmountType(AmountType.PERCENTAGE);
      } else {
        form.setFieldsValue(lateChargeSetting);
      }
    } else {
      form.resetFields();
      setAmountType(AmountType.NUMBER);
    }
  }, [form, lateChargeSetting]);

  const handleCreateLateChargeSetting = useCallback(
    async (data: LateChargeSettingFormFields) => {
      try {
        await createLateChargeSetting({
          ...data,
          amount: amountType === AmountType.PERCENTAGE ? `${data.amount}%` : String(data.amount),
        }).unwrap();

        showNotification('info', t('Success'), t('Late charge setting has been successfully created'));

        handleClose();

        form.resetFields();
      } catch {
        showNotification('error', t('Error'), t('Error when creating late charge setting'));
      }
    },
    [amountType, createLateChargeSetting, form, handleClose, t],
  );

  const handleUpdateLateChargeSetting = useCallback(
    async (data: LateChargeSettingFormFields) => {
      try {
        if (lateChargeSetting) {
          await updateLateChargeSetting({
            ...lateChargeSetting,
            ...data,
            amount: amountType === AmountType.PERCENTAGE ? `${data.amount}%` : String(data.amount),
          }).unwrap();

          showNotification('info', t('Success'), t('Late charge setting has been successfully updated'));
        }

        handleClose();

        form.resetFields();
      } catch {
        showNotification('error', t('Error'), t('Error when updating late charge setting'));
      }
    },
    [amountType, form, handleClose, lateChargeSetting, t, updateLateChargeSetting],
  );

  const handleSubmit = useCallback(
    async (data: LateChargeSettingFormFields): Promise<void> => {
      switch (mode) {
        case DrawerMode.LATE_CHARGE_SETTING_CREATE:
          await handleCreateLateChargeSetting(data);
          break;
        case DrawerMode.LATE_CHARGE_SETTING_EDIT:
          await handleUpdateLateChargeSetting(data);
          break;
      }
    },
    [handleCreateLateChargeSetting, handleUpdateLateChargeSetting, mode],
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col px-10 py-4" disabled={isForbiddenEdit}>
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-3xl">
          {!isCreateMode ? `${t('Edit')} #${lateChargeSetting?.name}` : t('Create late charge setting')}
        </div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={handleClose}>
          {t('Close')}
        </div>
      </div>

      <Form.Item
        label={t('Late charge setting name')}
        name="name"
        rules={[{ required: true, message: `${t('Please, enter late charge setting name')}!` }]}
      >
        <Input placeholder={t('Please, enter late charge setting name')} bordered />
      </Form.Item>

      <div className="flex gap-4">
        <Form.Item
          label={t('Overdue days limit')}
          name="overdueDaysLimit"
          className="w-[85%]"
          rules={[{ required: true, message: `${t('Please, enter overdue days limit')}!` }]}
        >
          <InputNumber placeholder={t('Overdue days limit')} bordered />
        </Form.Item>
        <Form.Item label={t('Min amount')} name="minAmount" rules={[{ required: true, message: `${t('Please, enter min amount')}!` }]}>
          <InputNumber placeholder={t('Min amount')} bordered addonAfter={currencySymbol} />
        </Form.Item>
      </div>
      <div className="flex gap-4">
        <Form.Item
          label={t('Amount type')}
          className="w-[85%]"
          rules={[{ required: true, message: `${t('Please, select amount type')}!` }]}
        >
          <Select
            options={amountTypeOptions}
            placeholder={t('Please, select amount type')}
            bordered
            onChange={changeAmountType}
            value={amountType}
          />
        </Form.Item>
        <Form.Item label={t('Amount')} name="amount" rules={[{ required: true, message: `${t('Please, enter amount')}!` }]}>
          <InputNumber
            placeholder={t('Amount')}
            bordered
            isDisabled={!amountType || isForbiddenEdit}
            addonAfter={amountType === AmountType.PERCENTAGE ? '%' : currencySymbol}
          />
        </Form.Item>
      </div>

      {/* <Divider className="mb-4 mt-0" /> */}

      {/* <Form.Item
        label={t('Notification days')}
        name="notificationDays"
        rules={[{ required: true, message: `${t('Please, select notification days')}!` }]}
      >
        <MultipleSelect placeholder={t('Select notification days')} bordered options={notificationDaysOptions} allowClear />
      </Form.Item> */}

      <Divider className="mb-6 mt-0" />

      {/* <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          () => ({
            async validator(_, value) {
              const isValidDescription = validateLocalizedJSON(value);

              if (!value || isValidDescription) {
                await Promise.resolve();
                return;
              }
              return await Promise.reject(new Error(t('Description must be a valid localized JSON')));
            },
          }),
        ]}
      >
        <TextArea placeholder={t('Add description...')} bordered rows={6} />
      </Form.Item> */}

      <Form.Item name="isStrictEmailReminder" valuePropName="checked">
        <Checkbox name="isStrictEmailReminder">{t('Setting with strict email reminder')}</Checkbox>
      </Form.Item>

      <Button type="submit" isLoading={isLoading} isDisabled={isForbiddenEdit}>
        {t('Save')}
      </Button>
    </Form>
  );
});
