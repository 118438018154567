import React, { FC, useEffect, ReactElement, memo, useCallback, useState, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Invoice, MarkAsPaidInvoiceParams, useBalancePay, useMarkAsPaidInvoiceMutation } from 'entities/Invoice';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { InputNumber } from 'shared/ui/InputNumber';
import { showNotification } from 'app/providers/NotificationsProvider';
import { PaymentMethod } from 'entities/Payment';
import { Select } from 'shared/ui/Select';
import { Form } from 'antd';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';
import { Checkbox } from 'shared/ui/Checkbox';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useGetCurrencySymbol } from 'app/appState';
import { InfoTag } from 'shared/ui/InfoTag';

interface MarkAsPaidInvoiceProps {
  invoice: Invoice;
  action: ReactElement;
}

export const MarkAsPaidInvoice: FC<MarkAsPaidInvoiceProps> = memo((props) => {
  const { invoice, action } = props;
  const { user } = invoice;

  const [form] = Form.useForm();
  const currencySymbol = useGetCurrencySymbol();

  const [isOpenedModal, setOpenedModal] = useState(false);
  const [overpayAmount, setOverpayAmount] = useState(0);
  const [addUserBalance, setAddUserBalance] = useState(false);
  const [isBalanceMethodActive, setIsBalanceMethodActive] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.MARK_AS_PAID_INVOICE);

  const { t } = useAppTranslation('contracts');

  const [markAsPaidInvoice, { isLoading }] = useMarkAsPaidInvoiceMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const toggleAddUserBalance = useCallback(
    (value: boolean): void => {
      setAddUserBalance(value);
      void form.validateFields(['amount']);
    },
    [form],
  );

  useEffect(() => {
    if (invoice) {
      form.setFieldValue('amount', invoice.debt);
    }
  }, [invoice, form]);

  const handlePaymentMethodChange = useCallback(
    (value: string): void => {
      void form.validateFields(['amount']);
      setIsBalanceMethodActive(value === PaymentMethod.USER_BALANCE);
    },
    [form],
  );

  const handleMarkAsPaidInvoice = useCallback(
    async (data: MarkAsPaidInvoiceParams): Promise<void> => {
      try {
        if (invoice) {
          await markAsPaidInvoice({ ...data, invoiceId: invoice.invoiceId, useOverpay: addUserBalance }).unwrap();
          showNotification('info', t('Success'), t('Invoice has been successfully marked as paid'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when marking invoice as paid'));
      } finally {
        closeModal();
      }
    },
    [invoice, markAsPaidInvoice, addUserBalance, t, closeModal],
  );

  const { isBalancePayAvaliable, amountToCharge } = useBalancePay(invoice);

  useEffect(() => {
    if (isBalanceMethodActive) {
      form.setFieldValue('amount', amountToCharge);
      toggleAddUserBalance(false);
    } else {
      form.setFieldValue('amount', invoice.debt);
    }
  }, [isBalanceMethodActive, amountToCharge, invoice, form, toggleAddUserBalance]);

  const paymentMethodOptions = useMemo(() => {
    const baseOptions = [
      {
        label: t('Stripe'),
        value: PaymentMethod.STRIPE,
      },
      {
        label: t('Bank transfer'),
        value: PaymentMethod.BANK_TRANSFER,
      },
      {
        label: t('Cash'),
        value: PaymentMethod.CASH,
      },
      {
        label: t('Other'),
        value: PaymentMethod.OTHER,
      },
    ];

    if (isBalancePayAvaliable) {
      baseOptions.unshift({
        label: t('From user balance'),
        value: PaymentMethod.USER_BALANCE,
      });
    }

    return baseOptions;
  }, [isBalancePayAvaliable, t]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[500px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleMarkAsPaidInvoice} className="flex flex-col" disabled={isForbidden}>
          <div className="font-semibold text-3xl mb-3">{t('Mark invoice as paid')}</div>

          <div className="font-semibold text-xl mb-2">
            {t('{{documentNumber}} debt is {{debt}} {{currencySymbol}}', {
              documentNumber: invoice.documentNumber,
              debt: invoice.debt,
              currencySymbol,
            })}
          </div>

          {isBalancePayAvaliable && (
            <div className="mt-2 mb-4">
              <InfoTag textSize="medium">
                {t(
                  'For this invoice you can charge up to {{amountToCharge}} {{currencySymbol}} from {{userName}} balance. To charge from user`s balance, select the appropriate payment method',
                  {
                    currencySymbol,
                    amountToCharge,
                    userName: `${user.firstName} ${user.lastName}`,
                  },
                )}
              </InfoTag>
            </div>
          )}

          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to mark this invoice as paid? You must select payment method!')}
          </div>

          <div className="my-4">
            <Form.Item
              label={t('Enter payment amount')}
              className="mb-0"
              name="amount"
              rules={[
                { required: true, message: `${t('Please, enter payment amount')}!` },
                () => ({
                  async validator(_, value) {
                    if (isBalanceMethodActive && value > amountToCharge) {
                      await Promise.reject(
                        new Error(
                          t('Value should be less or equal to {{amountToCharge}}', {
                            amountToCharge,
                          }),
                        ),
                      );
                      return;
                    }

                    if (value > invoice.debt) {
                      const overpay = roundNumber(value - invoice.debt);
                      setOverpayAmount(overpay);

                      addUserBalance
                        ? await Promise.resolve()
                        : await Promise.reject(
                            new Error(
                              t('{{value}} is more then invoice debt {{debt}}, do you want to add {{overpayAmount}} to user balance ?', {
                                debt: invoice.debt,
                                overpayAmount: overpay,
                                value,
                              }),
                            ),
                          );
                      return;
                    }

                    setOverpayAmount(0);
                    await Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter payment amount')} bordered />
            </Form.Item>

            {overpayAmount > 0 && !isBalanceMethodActive && (
              <div>
                <Checkbox className="mt-3" name="addUserBalance" checked={addUserBalance} onChange={toggleAddUserBalance}>
                  <span className="text-m">
                    {t('Add {{amount}} {{currencySymbol}} to balance', { amount: overpayAmount, currencySymbol })}
                  </span>
                </Checkbox>
              </div>
            )}

            <Form.Item
              label={t('Select payment method')}
              className="mt-4"
              name="paymentMethod"
              rules={[{ required: true, message: `${t('Please, select payment method')}!` }]}
            >
              <Select
                options={paymentMethodOptions}
                onChange={handlePaymentMethodChange}
                placeholder={t('Select payment method')}
                bordered
              />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
