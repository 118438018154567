import { Divider } from 'antd';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getBoxRentalPrice } from 'entities/Box';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getSelectedBoxes } from '../../model/selectors/getSelectedBoxes';
import { getSelectedEmail } from '../../model/selectors/getSelectedEmail';
import { getSelectedWarehouse } from '../../model/selectors/getSelectedWarehouse';
import { useSendOfferMutation } from 'entities/Lead';
import { showNotification } from 'app/providers/NotificationsProvider';
import { getSelectedLanguage } from '../../model/selectors/getSelectedLanguage';
import { AppLanguage } from 'app/config/i18Config/types';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { TextArea } from 'shared/ui/TextArea';
import { sendOfferModalActions } from '../../model/slice/sendOfferModalSlice';
import { getOfferNote } from '../../model/selectors/getOfferNote';

interface SummaryStepProps {
  onClose: () => void;
}

export const SummaryStep: FC<SummaryStepProps> = memo((props) => {
  const { onClose } = props;

  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const selectedWarehouse = useAppSelector(getSelectedWarehouse);
  const selectedEmail = useAppSelector(getSelectedEmail);
  const selectedBoxes = useAppSelector(getSelectedBoxes);
  const note = useAppSelector(getOfferNote);
  const language = useAppSelector(getSelectedLanguage);

  const { data: rentOptions } = useGetRentOptionsQuery(
    { warehouseId: selectedWarehouse?.warehouseId },
    { skip: !selectedWarehouse?.warehouseId },
  );

  const [sendOffer, { isLoading }] = useSendOfferMutation();

  const unitSizes = useMemo(() => {
    const selectedSizes = selectedBoxes.map((box) => box.sizeCode.square.toString());

    return Array.from(new Set(selectedSizes));
  }, [selectedBoxes]);

  const filteredByUnitSizeRentOptions = useMemo(() => {
    return rentOptions?.filter((option) => {
      // no need to filter rent option dateFrom dateTo as we don't know exact contract start date
      return unitSizes.some((size) => option.unitSizes?.includes(size)) || (option.unitSizes === null && option.showAsPromotion);
    });
  }, [rentOptions, unitSizes]);

  const offerDetailsBullets = useMemo(
    () => [
      { label: t('Email'), value: selectedEmail },
      {
        label: t('Warehouse'),
        value: `${selectedWarehouse?.name}`,
      },
    ],
    [selectedEmail, selectedWarehouse?.name, t],
  );

  const offerBoxesBullets = useMemo(() => {
    const boxesBullets = selectedBoxes.map((box) => {
      return {
        label: `${box.name} (${box.sizeCode.square} m2)`,
        value: `${getBoxRentalPrice(box)} ${currencySymbol}`,
        emptyValueSymbol: `- ${currencySymbol}`,
      };
    });

    return boxesBullets;
  }, [currencySymbol, selectedBoxes]);

  const offerRentOptionsBullets = useMemo(() => {
    if (filteredByUnitSizeRentOptions) {
      const rentOptionsBullets = filteredByUnitSizeRentOptions.map((rentOption) => {
        return {
          label: getLocalizedString(rentOption.label),
          value: getLocalizedString(rentOption.description),
        };
      });

      return rentOptionsBullets;
    }

    return [];
  }, [filteredByUnitSizeRentOptions]);

  const handleSendOffer = useCallback(async () => {
    try {
      if (selectedEmail && selectedWarehouse) {
        const boxId = selectedBoxes.map((box) => box.boxId);

        const sendOfferData = {
          email: selectedEmail,
          warehouseId: selectedWarehouse?.warehouseId,
          boxId,
          note,
          language: language || AppLanguage.de,
        };

        await sendOffer(sendOfferData).unwrap();

        showNotification('info', t('Success'), t('Offer has been successfully sent'));

        onClose();
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error'), t('Error when sending offer'));
    }
  }, [language, note, onClose, selectedBoxes, selectedEmail, selectedWarehouse, sendOffer, t]);

  const noteChange = useCallback(
    (note: string) => {
      dispatch(sendOfferModalActions.setNote(note));
    },
    [dispatch],
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col pl-3">
          <div className="font-semibold text-2xl mb-4">{t('Offer details')}</div>
          <div className="border border-secondaryAccent rounded-lg py-7 px-3 w-[700px]">
            <BulletsTable heading={t('Details')} rows={offerDetailsBullets} theme="clear" />
            <Divider className="my-4" />
            <BulletsTable heading={t('Boxes')} rows={offerBoxesBullets} theme="clear" />

            <div>
              <Divider className="my-4" />
              {offerRentOptionsBullets.length > 0 ? (
                <BulletsTable heading={t('Rent options')} rows={offerRentOptionsBullets} theme="clear" />
              ) : (
                <div className="mb-3 text-accent font-semibold text-base">{t('No rent options for these sizes!')}</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[350px] pr-3">
          <div className="font-semibold text-2xl mb-4">{t('Note')}</div>
          <TextArea bordered placeholder={t('Add your note...')} className="!h-full" onChange={noteChange} />
        </div>
      </div>

      <div className="flex self-end mt-8">
        <Button onClick={handleSendOffer} isLoading={isLoading}>
          {t('Send offer')}
        </Button>
      </div>
    </div>
  );
});
