import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableRowFullInfo, TableRowFullInfoModalSchema } from '../types';

const initialState: TableRowFullInfoModalSchema = {
  rowFullInfo: null,
};

const tableRowFullInfoModalSlice = createSlice({
  name: 'tableRowFullInfoModal',
  initialState,
  reducers: {
    openModal: (state: TableRowFullInfoModalSchema, action: PayloadAction<TableRowFullInfo>) => {
      state.rowFullInfo = action.payload;
    },
    closeModal: (state: TableRowFullInfoModalSchema) => {
      state.rowFullInfo = null;
    },
  },
});

export const { actions: tableRowFullInfoModalActions, reducer: tableRowFullInfoModalReducer } = tableRowFullInfoModalSlice;
