import React, { FC, memo, PropsWithChildren, ReactNode } from 'react';
import { Modal as AntdModal } from 'antd';
import classNames from 'classnames';

import s from './index.module.scss';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose?: () => void;
  footer?: ReactNode;
  className?: string;
  centered?: boolean;
  closeIcon?: boolean;
  width?: number;
  paddingLess?: boolean;
  zIndex?: number;
}

export const Modal: FC<ModalProps> = memo((props) => {
  const {
    isOpen,
    onClose,
    children,
    footer = false,
    className,
    centered = true,
    closeIcon = true,
    width,
    paddingLess = false,
    zIndex,
  } = props;

  const modalClass = classNames(s.modal, className, {
    [s.paddingLess]: paddingLess,
    [s.marginLess]: !footer,
  });

  return (
    <AntdModal
      open={isOpen}
      onCancel={onClose}
      className={modalClass}
      footer={footer}
      centered={centered}
      closeIcon={closeIcon}
      width={width}
      zIndex={zIndex}
    >
      {children}
    </AntdModal>
  );
});
