import React, { FC, ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { CreditPaymentParams, useCreditPaymentMutation } from 'entities/Payment';
import { InputNumber } from 'shared/ui/InputNumber';
import { WarnTag } from 'shared/ui/WarnTag';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface CreditPaymentProps {
  paymentId: string;
  amount: number;
  action: ReactElement;
}

export const CreditPayment: FC<CreditPaymentProps> = memo((props) => {
  const { paymentId, amount, action } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.CREDIT_PAYMENT);

  const [form] = Form.useForm();

  const { t } = useAppTranslation('contracts');

  const [creditPayment, { isLoading }] = useCreditPaymentMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const handleCreditPayment = useCallback(
    async (data: CreditPaymentParams): Promise<void> => {
      try {
        if (paymentId) {
          await creditPayment({ ...data, paymentId }).unwrap();
          showNotification('info', t('Success'), t('Payment has been successfully credited'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when crediting payment'));
      } finally {
        closeModal();
      }
    },
    [paymentId, creditPayment, t, closeModal],
  );

  useEffect(() => {
    form.setFieldValue('creditedAmount', amount);
  }, [amount, form]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleCreditPayment} className="flex flex-col" disabled={isForbidden}>
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Credit payment')}</div>

          <div className="mt-2 mb-6">
            <WarnTag textSize="medium" disableIcon>
              {t('If payment related to deposit invoice, partial credit notes may cause deposit refund problems.')}
            </WarnTag>
          </div>

          <div className="text-primaryLight font-normal">{t('Are you sure you want to credit this payment?')}</div>

          <div className="my-5">
            <Form.Item
              label={t('Enter credited amount')}
              name="creditedAmount"
              rules={[
                { required: true, message: `${t('Please, enter credited amount')}!` },
                () => ({
                  async validator(_, value) {
                    if (!value || value <= amount) {
                      await Promise.resolve();
                      return;
                    }
                    return await Promise.reject(new Error(`${t('Credited amount must be less or equal')} ${amount}!`));
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter credited amount')} bordered />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
