import { ComponentType, useCallback } from 'react';
import { SHOW_SPECIAL_PROMOTION } from 'app/config/envVariables';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getOpenedState } from '../../model/selectors/getOpenedState';
import { specialPromotionModalActions } from '../../model/slice/specialPromotionModalSlice';
import { SPECIAL_PROMOTION_MODAL_KEY } from '../constants';

interface HookApi {
  isOpened: boolean;
  showSpecialPromotion: boolean;
  initSpecialPromotionModal: (content: ComponentType) => void;
  openModal: (content: ComponentType) => void;
  closeModal: () => void;
}

export const useSpecialPromotionModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(getOpenedState);

  const specialPromotionAlreadyClosed = localStorage.getItem(SPECIAL_PROMOTION_MODAL_KEY) === 'false';

  const showSpecialPromotion = SHOW_SPECIAL_PROMOTION;

  const initSpecialPromotionModal = useCallback(
    (Content: ComponentType) => {
      showSpecialPromotion && !specialPromotionAlreadyClosed && dispatch(specialPromotionModalActions.initModal(Content));
    },
    [dispatch, showSpecialPromotion, specialPromotionAlreadyClosed],
  );

  const openModal = useCallback(
    (Content: ComponentType) => {
      dispatch(specialPromotionModalActions.openModal(Content));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(specialPromotionModalActions.closeModal());
  }, [dispatch]);

  return {
    isOpened,
    showSpecialPromotion,
    initSpecialPromotionModal,
    openModal,
    closeModal,
  };
};
