import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { MultipleSelect } from 'shared/ui/MultipleSelect';
import { getUserName, useGetPaginatedUsersQuery, UserRoles } from 'entities/User';
import { NotesListFilterKeys } from 'pages/NotesPage/model/types';

interface AssignedUsersSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  onClear: (filterKey: string) => void;
}

export const AssignedUsersSelect: FC<AssignedUsersSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('contracts');

  const { data } = useGetPaginatedUsersQuery({ role: [UserRoles.MANAGER, UserRoles.SUPERUSER] });

  const managersSelectOptions = useMemo<Array<{ label: string; value: string }>>(() => {
    if (!data?.content) {
      return [];
    }

    return data.content.map((user) => {
      return {
        label: getUserName(user),
        value: user.userId,
      };
    });
  }, [data]);

  const clearFilter = useCallback(() => {
    onClear(NotesListFilterKeys.assignedUsers);
  }, [onClear]);

  const handleChange = useCallback(
    (value: string[]): void => {
      value.length ? onChange(value) : clearFilter();
    },
    [onChange, clearFilter],
  );

  return (
    <MultipleSelect
      containerClassName="flex-1"
      className="w-full"
      label={t('Assigned to')}
      placeholder={t('All')}
      bordered
      allowClear
      options={managersSelectOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
