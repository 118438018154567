import { Invoice, InvoiceItemType } from 'entities/Invoice/model/types';
import { useMemo } from 'react';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface HookApi {
  isBalancePayAvaliable: boolean;
  amountToCharge: number;
}

export const useBalancePay = (invoice: Invoice): HookApi => {
  const { user } = invoice;

  const invoiceDeposit = useMemo(
    () =>
      roundNumber(
        invoice.invoiceItems
          .filter((item) => item.invoiceItemType === InvoiceItemType.DEPOSIT)
          .reduce((acc, invoiceItem) => acc + invoiceItem.totalPrice, 0),
      ),
    [invoice],
  );

  const isBalancePayAvaliable = useMemo(() => {
    const invoiceDebt = invoice.debt;

    return user.balance > 0 && invoiceDeposit < invoiceDebt;
  }, [invoice.debt, invoiceDeposit, user.balance]);

  const amountToCharge = useMemo(() => {
    if (user.balance === 0 || !isBalancePayAvaliable) {
      return 0;
    }

    const debtWithoutDeposit = roundNumber(invoice.debt - invoiceDeposit);

    return debtWithoutDeposit < user.balance ? debtWithoutDeposit : user.balance;
  }, [invoice.debt, invoiceDeposit, isBalancePayAvaliable, user.balance]);

  return { isBalancePayAvaliable, amountToCharge };
};
