import React, { FC, ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { MarkAsRefundedPaymentParams, Payment, PaymentMethod, useMarkAsRefundedPaymentMutation } from 'entities/Payment';
import { Select } from 'shared/ui/Select';
import { Form } from 'antd';
import { InputNumber } from 'shared/ui/InputNumber';
import { WarnTag } from 'shared/ui/WarnTag';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';

interface MarkAsRefundedPaymentProps {
  payment: Payment;
  action: ReactElement;
}

export const MarkAsRefundedPayment: FC<MarkAsRefundedPaymentProps> = memo((props) => {
  const { payment, action } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const isForbidden = useCheckPermission(UserPermissions.MARK_AS_REFUNDED);

  const maxRefundAmount = useMemo(
    () => (payment.creditNote ? payment.creditNote.creditedAmount : payment.amount),
    [payment.amount, payment.creditNote],
  );

  const [form] = Form.useForm();

  const { t } = useAppTranslation('contracts');

  const [markAsRefundedPayment, { isLoading }] = useMarkAsRefundedPaymentMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  useEffect(() => {
    form.setFieldValue('amount', maxRefundAmount);
  }, [maxRefundAmount, form]);

  const handleMarkAsRefundedPayment = useCallback(
    async (data: MarkAsRefundedPaymentParams): Promise<void> => {
      try {
        if (payment) {
          await markAsRefundedPayment({ ...data, paymentId: payment.paymentId }).unwrap();
          showNotification('info', t('Success'), t('Payment has been successfully marked as refunded'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when marking payment as refunded'));
      } finally {
        closeModal();
      }
    },
    [payment, markAsRefundedPayment, t, closeModal],
  );

  const paymentMethodOptions = [
    {
      label: t('Stripe'),
      value: PaymentMethod.STRIPE,
    },
    {
      label: t('Bank transfer'),
      value: PaymentMethod.BANK_TRANSFER,
    },
    {
      label: t('Cash'),
      value: PaymentMethod.CASH,
    },
  ];

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleMarkAsRefundedPayment} className="flex flex-col" disabled={isForbidden}>
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Mark payment as refunded')}</div>

          <div className="mt-2 mb-6">
            <WarnTag textSize="medium" disableIcon>
              {t('If payment related to deposit invoice, partial credit notes may cause deposit refund problems.')}
            </WarnTag>
          </div>

          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to mark this payment as refunded? You must select payment method!')}
          </div>

          <div className="my-5">
            <Form.Item
              label={t('Select payment method')}
              name="paymentMethod"
              rules={[{ required: true, message: `${t('Please, select payment method')}!` }]}
            >
              <Select options={paymentMethodOptions} placeholder={t('Select payment method')} bordered />
            </Form.Item>
            <Form.Item
              label={t('Enter amount')}
              name="amount"
              rules={[
                { required: true, message: `${t('Please, enter amount')}!` },
                () => ({
                  async validator(_, value) {
                    if (!value || value <= maxRefundAmount) {
                      await Promise.resolve();
                      return;
                    }
                    return await Promise.reject(new Error(`${t('Amount must be less or equal')} ${maxRefundAmount}!`));
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter credited amount')} bordered />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
