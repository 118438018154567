import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ReactComponent as EditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { Note, NoteType, useDeleteNoteMutation, useGetPaginatedNotesQuery, useUpdateNoteMutation } from 'entities/Note';
import { Toggle } from 'shared/ui/Toggle';
import { Paragraph } from 'shared/ui/Paragraph';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { DrawerMode, noteDrawerActions } from 'features/NoteDrawer';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { BadgeColor, RowBadge } from 'shared/ui/ServerTable/RowBadge';
import { getLoggedUserData, getUserName, UserPermissions } from 'entities/User';
import { NotesListFilter } from './NotesListFilter';
import { useTableFilterContext } from 'features/TableFilter';
import { transformNotesListFilters } from '../utils/transformNotesListFilters';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface NotesTableProps {
  isCompleted: boolean;
}

export const NotesTable: FC<NotesTableProps> = memo((props) => {
  const { isCompleted } = props;

  const [selectedNote, setSelectedNote] = useState<Nullable<Note>>(null);
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('contracts');
  const dispatch = useAppDispatch();

  const loggedUser = useAppSelector(getLoggedUserData);

  const isForbiddenDelete = useCheckPermission(UserPermissions.DELETE_UNITS);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const [deleteNote, { isLoading: isDeletionLoading }] = useDeleteNoteMutation();
  const [updateNote] = useUpdateNoteMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
    setSelectedNote(null);
  }, []);

  const handleDeleteNote = useCallback(async (): Promise<void> => {
    try {
      if (selectedNote) {
        await deleteNote(selectedNote.noteId).unwrap();
        showNotification('info', t('Success'), t('Note deleted successfully'));
      }
    } catch (error) {
      showNotification('error', t('Error'), t('Error when deleting note'));
      console.log('error', error);
    } finally {
      closeConfirmModal();
    }
  }, [selectedNote, deleteNote, t, closeConfirmModal]);

  const createNote = useCallback((): void => {
    dispatch(noteDrawerActions.setOpenNoteDrawer({ mode: DrawerMode.NOTE_CREATE }));
  }, [dispatch]);

  const editNote = useCallback(
    (note: Note): void => {
      dispatch(noteDrawerActions.setOpenNoteDrawer({ mode: DrawerMode.NOTE_EDIT, note }));
    },
    [dispatch],
  );

  const toggleNoteStatus = useCallback(
    async (note: Note) => {
      try {
        await updateNote({
          noteId: note.noteId,
          isCompleted: !note.isCompleted,
          description: `${t('Note status changed by')} ${getUserName(loggedUser)} (${loggedUser?.userId})`,
        }).unwrap();

        showNotification('info', t('Success'), t('Note status changed successfully'));
      } catch (error) {
        showNotification('error', t('Error'), t('Error when changing note status'));
        console.log('error', error);
      }
    },
    [loggedUser, t, updateNote],
  );

  const tableActions: Array<ServerTableActions<Note>> = useMemo(
    () => [
      {
        name: t('Create Note'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createNote,
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [createNote, setFiltersOpened, t],
  );

  const rowActions: Array<ServerTableRowActions<Note>> = useMemo(
    () => [
      {
        name: 'editNote',
        icon: <EditIcon />,
        theme: 'clear',
        description: t('Edit note'),

        onClick: editNote,
      },
      {
        name: 'deleteNote',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete note'),
        onClick: (selectedNote: Note) => {
          setSelectedNote(selectedNote);
          openConfirmModal();
        },
      },
    ],
    [editNote, openConfirmModal, t],
  );

  const columns = useMemo<ColumnsType<Note>>(
    () => [
      {
        title: t('Created by'),
        key: 'createdBy',
        render: (_, record) => {
          const badgeColor: Record<NoteType, BadgeColor> = {
            [NoteType.TERMINATION]: 'error',
            [NoteType.OVERDUE]: 'primary',
            [NoteType.CHECK]: 'warn',
            [NoteType.CALL]: 'primaryLight',
            [NoteType.EMAIL]: 'primaryLight',
            [NoteType.MEETING]: 'primaryLight',
            [NoteType.OTHER]: 'success',
          };

          return (
            <div className="text-primary font-normal">
              {record.createdBy ? `${record.createdBy.firstName} ${record.createdBy.lastName}` : t('System')}
              <RowBadge color={badgeColor[record.noteType]} title={t(record.noteType)} />
            </div>
          );
        },
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        render: (_, record) => <div className="font-normal text-primary">{record.warehouse?.name}</div>,
      },
      {
        title: t('Contract'),
        key: 'contractNumber',
        render: (_, record) => <div className=" text-primary font-normal">{record?.contractNumber}</div>,
      },
      {
        title: t('Assigned to'),
        key: 'assignedUsers',
        render: (_, record) => (
          <div className="font-normal text-primary">
            {record.assignedUsers?.map((user) => {
              return (
                <div key={user.userId}>
                  {user.firstName} {user.lastName}
                </div>
              );
            })}
          </div>
        ),
      },
      {
        title: t('Date of creation'),
        key: 'createdAt',
        render: (_, record) => <div className=" text-primary font-normal">{getClientDateFormat(record.createdAt)}</div>,
      },
      {
        title: t('Date of deadline'),
        key: 'deadlineDate',
        render: (_, record) => (
          <div className=" text-primary font-normal">
            {record?.deadlineDate ? getClientDateFormat(record.deadlineDate) : t('Not specified')}
          </div>
        ),
      },
      {
        title: t('Status'),
        key: 'isCompleted',
        render: (_, record) => (
          <div>
            <Toggle
              checked={record.isCompleted}
              onChange={async () => {
                await toggleNoteStatus(record);
              }}
            />
            <div className="text-xs text-primaryLight mt-2 ">{t(record.isCompleted ? 'Completed' : 'Incompleted')}</div>
          </div>
        ),
      },
      {
        title: t('Description'),
        key: 'description',
        render: (_, record) => {
          const paragraphs = record.description?.split('\n');

          const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
            <p key={index} className="font-normal !mb-0">
              {paragraph}
            </p>
          ));

          return <Paragraph rows={4}>{paragraphElements}</Paragraph>;
        },
      },
    ],
    [toggleNoteStatus, t],
  );

  const highlightRow = (record: Note): string => {
    const today = dayjs().startOf('day');

    const isExpiredNote = dayjs(record?.deadlineDate).isSame(today, 'day') || dayjs(today).isAfter(record?.deadlineDate);

    return classNames({
      'bg-errorLight': !record.isCompleted && isExpiredNote,
      'bg-successLight': record.isCompleted,
    });
  };

  return (
    <div className="space-y-4">
      <ServerTable
        fetchData={useGetPaginatedNotesQuery}
        columns={columns}
        rowKey="noteId"
        title={t(isCompleted ? 'Completed Notes' : 'Incompleted Notes')}
        tableActions={tableActions}
        tableActionsPosition="prev"
        rowActions={rowActions}
        rowClassName={highlightRow}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        search
        defaultFilters={{
          isCompleted,
          ...transformNotesListFilters(appliedFilters),
        }}
      />
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Delete note')}
        description={t('Are you sure you want to delete this note?')}
        isLoading={isDeletionLoading}
        onOk={handleDeleteNote}
        onCancel={closeConfirmModal}
        isConfirmDisabled={isForbiddenDelete}
      />
      <NotesListFilter />
    </div>
  );
});
