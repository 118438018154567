import { Contract, ContractItem } from 'entities/Contract';
import { CreditNote } from 'entities/CreditNote';
import { Payment } from 'entities/Payment';
import { User } from 'entities/User';
import { Warehouse } from 'entities/Warehouse';

export enum InvoiceFrequencyType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum InvoiceItemType {
  LATE_CHARGE = 'LateCharge',
  PENALTY = 'Penalty',
  DEPOSIT = 'Deposit',
  OTHER = 'Other',
  BOX = 'Box',
  INSURANCE = 'Insurance',
  PARKING_PLACE = 'ParkingPlace',
  POSTAL_BOX = 'PostalBox',
  RENTING_STUFF = 'RentingStuff',
}

export interface InvoiceItem {
  invoiceItemId: string;
  invoiceItemType: InvoiceItemType;
  discountPercent: number;
  initialPrice: number;
  totalPrice: number;
  vatRatePercent: number;
  contractItem: Nullable<ContractItem>;
  quantity: number;
  dateFrom?: string;
  dateTo?: string;
  description?: string;
  invoice: Invoice;
}

export interface InvoiceDebtActionDocument {
  invoiceDebtActionId: string;
  actionType: ActionType;
  amount: number;
  invoice: Invoice;
  description?: string;
}

export interface Invoice {
  invoiceId: string;
  debt: number;
  amount: number;
  documentNumber: string;
  documentType: DocumentType;
  user: User;
  contractItem: ContractItem;
  creditNotes: CreditNote[];
  issuedBy: Nullable<string>;
  cancellationDate: Nullable<string>;
  cancelledBy: Nullable<string>;
  contract: Nullable<Contract>;
  invoiceItems: InvoiceItem[];
  invoiceDebtActions: InvoiceDebtActionDocument[];
  payments: Payment[];
  currency: string;
  warehouse?: Warehouse;
  invoiceAddress: string;
  description: Nullable<string>;
  invoiceEmail: string;
  dueDate?: string;
  dateFrom?: string;
  dateTo?: string;
  relatedInvoiceId?: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreditInvoiceParams {
  invoiceId: string;
  description?: Nullable<string>;
  creditedAmount: number;
}

export interface MarkAsPaidInvoiceParams {
  invoiceId: string;
  description?: Nullable<string>;
  paymentMethod: string;
  amount: number;
  useOverpay?: boolean;
}

export enum ActionType {
  REFILL = 'Refill',
  CHARGE = 'Charge',
}

export enum DocumentType {
  INVOICE = 'Invoice',
  DEPOSIT = 'Deposit',
  CREDIT_NOTE = 'CreditNote',
  PAYMENT = 'Payment',
}

export enum RentState {
  ACTIVE_RESERVATION = 'active reservation',
  ACTIVE_CONTRACT = 'active contract',
  AVAILABLE = 'available',
  ALL = 'all',
}

export enum InvoiceStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  OVERDUE = 'overdue',
}
