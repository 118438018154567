import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Email, EmailType, useGetSavedEmailsQuery } from 'entities/Email';
import { RenderHTMLModal, useRenderHTMLModal } from 'features/RenderHTML';
import { RenderPDFModal, useRenderPDFModal } from 'features/RenderPDF';
import { ReactComponent as EmailIcon } from 'shared/assets/icons/EmailIcon.svg';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { Modal } from 'shared/ui/Modal';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useOfferHistoryModal } from '../utils/hooks/useOfferHistoryModal';

export const OfferHistoryModal: FC = memo(() => {
  const { t } = useAppTranslation('user');

  const { isOpened, offerEmail, closeOfferHistoryModal } = useOfferHistoryModal();
  const { openRenderPDFModal } = useRenderPDFModal();
  const { openRenderHTMLModal } = useRenderHTMLModal();

  const columns = useMemo<ColumnsType<Email>>(
    () => [
      {
        title: t('Email'),
        key: 'email',
        render: (_, record) => record.to,
      },
      {
        title: t('Type'),
        key: 'emailType',
        render: (_, record) => t(record.emailType),
      },

      { title: t('Sent'), key: 'sent', render: (_, record) => getClientDateFormat(record.createdAt) },
    ],
    [t],
  );

  const rowActions: Array<ServerTableRowActions<Email>> = useMemo(
    () => [
      {
        name: 'showMail',
        icon: <EmailIcon className="[&>path]:stroke-accent" />,
        theme: 'clear',
        description: t('Mail'),
        onClick: (selectedEmail) => {
          openRenderHTMLModal(selectedEmail.htmlTemplate);
        },
      },
      {
        name: 'showPdfAttachment',
        icon: <DocumentIcon className="[&>path]:stroke-accent" />,
        theme: 'clear',
        description: t('PDF attachment'),
        onClick: (selectedEmail) => {
          selectedEmail.base64Attachment && openRenderPDFModal(selectedEmail.base64Attachment);
        },
        isDisabled: (selectedEmail) => !selectedEmail.base64Attachment,
      },
    ],
    [openRenderHTMLModal, openRenderPDFModal, t],
  );

  return (
    <>
      <Modal width={1100} isOpen={isOpened} onClose={closeOfferHistoryModal}>
        <ServerTable
          columns={columns}
          fetchData={useGetSavedEmailsQuery}
          defaultFilters={{
            to: offerEmail,
            emailType: [EmailType.SEND_OFFER, EmailType.FIRST_LEAD_REMINDER, EmailType.SECOND_LEAD_REMINDER, EmailType.THIRD_LEAD_REMINDER],
          }}
          rowKey="emailIdId"
          title={t('Offer history')}
          rowActions={rowActions}
        />
      </Modal>
      <RenderPDFModal />
      <RenderHTMLModal />
    </>
  );
});
