import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferHistoryModalSchema } from '../types';

const initialState: OfferHistoryModalSchema = {
  email: null,
};

const offerHistoryModalSlice = createSlice({
  name: 'offerHistoryModal',
  initialState,
  reducers: {
    openModal: (state: OfferHistoryModalSchema, action: PayloadAction<Nullable<string>>) => {
      state.email = action.payload;
    },
    closeModal: (state: OfferHistoryModalSchema) => {
      state.email = null;
    },
  },
});

export const { actions: offerHistoryModalActions, reducer: offerHistoryModalReducer } = offerHistoryModalSlice;
