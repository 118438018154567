import React, { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useOnLockStatusChanged } from 'entities/Box';
import { ReactComponent as BoxIcon } from 'shared/assets/icons/BoxTabIcon.svg';
import { ReactComponent as SizeCodeIcon } from 'shared/assets/icons/SizeCodeTabIcon.svg';
import { showNotification } from 'app/providers/NotificationsProvider';
import { TableFilterContextProvider } from 'features/TableFilter';
import { BoxesTable } from './BoxesTable';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { SizeCodesTable } from './SizeCodesTable';
import { useRole } from 'entities/User';

export const BoxesPage: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'boxes', 'common']);
  const [selectedTabKey, setSelectedTabKey] = useState('Boxes');

  const { isManagement } = useRole();

  const newLockStatus = useOnLockStatusChanged();

  useEffect(() => {
    if (newLockStatus) {
      showNotification(
        'warning',
        t('Lock status change'),
        t('Box {{boxNumber}} (lock {{lockId}}) has change status to {{status}}', {
          boxNumber: newLockStatus.boxNumber,
          lockId: newLockStatus.lockId,
          status: newLockStatus.status,
        }),
      );
    }
  }, [newLockStatus, t]);

  const renderTabContent = useCallback((): Nullable<ReactNode> => {
    switch (selectedTabKey) {
      case 'Boxes':
        return (
          <TableFilterContextProvider>
            <BoxesTable />
          </TableFilterContextProvider>
        );
      case 'Size codes':
        return (
          <TableFilterContextProvider>
            <SizeCodesTable />
          </TableFilterContextProvider>
        );

      default:
        return null;
    }
  }, [selectedTabKey]);

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  const tabs = useMemo(() => {
    const commonTabs = [
      { key: 'Boxes', label: t('Boxes'), icon: <BoxIcon /> },
      { key: 'Size codes', label: t('Size codes'), icon: <SizeCodeIcon /> },
    ];

    if (!isManagement) {
      commonTabs.pop();
    }

    return commonTabs;
  }, [isManagement, t]);

  return (
    <>
      <TabSwitcher tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        {renderTabContent()}
      </TabSwitcher>
    </>
  );
});
