import { transformBooleanString } from 'shared/utils/helpers/transformBooleanString';

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST || 'http://localhost:3000';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

export const API_HOST = process.env.REACT_APP_API_HOST || 'http://localhost:3001';

export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST || 'http://localhost:3001';

export const DEFAULT_APP_LANGUAGE = process.env.REACT_APP_DEFAULT_APP_LANGUAGE || 'de_DE';

export const SHOW_SPECIAL_PROMOTION = transformBooleanString(process.env.REACT_APP_SHOW_SPECIAL_PROMOTION) || true;
