import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { NoteDrawer } from 'features/NoteDrawer';
import { NotesTable } from './NotesTable';
import { TableFilterContextProvider } from 'features/TableFilter';

export const NotesPage: FC = memo(() => {
  const [selectedTabKey, setSelectedTabKey] = useState('incompleted');

  const { t } = useAppTranslation('contracts');

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  const renderTabContent = useCallback((): Nullable<ReactNode> => {
    switch (selectedTabKey) {
      case 'completed':
        return <NotesTable isCompleted={true} />;
      default:
        return <NotesTable isCompleted={false} />;
    }
  }, [selectedTabKey]);

  const tabs = [
    { key: 'incompleted', label: t('Incompleted') },
    { key: 'completed', label: t('Completed') },
  ];

  return (
    <>
      <TabSwitcher className="mt-7" tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        <TableFilterContextProvider>{renderTabContent()}</TableFilterContextProvider>
      </TabSwitcher>
      <NoteDrawer />
    </>
  );
});
