import React, { FC, useCallback, useEffect, useState, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { BoxForListing, useUpdateBoxNoteMutation } from 'entities/Box';
import { ReactComponent as MapIcon } from 'shared/assets/icons/MapIcon.svg';
import { ReactComponent as SquareIcon } from 'shared/assets/icons/SquareIcon.svg';
import { ReactComponent as WalletIcon } from 'shared/assets/icons/WalletIcon.svg';
import { ReactComponent as SizeIcon } from 'shared/assets/icons/SizeIcon.svg';
import { ReactComponent as WarehouseIcon } from 'shared/assets/icons/WarehouseIcon.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/LockIcon.svg';
import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';
import { useGetCurrencySymbol } from 'app/appState';
import { TextArea } from 'shared/ui/TextArea';
import { showNotification } from 'app/providers/NotificationsProvider';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface BoxInfoCardProps {
  isOpen: boolean;
  onClose: () => void;
  box: Nullable<BoxForListing>;
}

export const BoxInfoCard: FC<BoxInfoCardProps> = memo((props) => {
  const { isOpen, onClose, box } = props;

  const { t } = useAppTranslation(['boxes', 'common']);

  const [note, setNote] = useState<Nullable<string>>(null);

  const [updateBoxNote, { isLoading }] = useUpdateBoxNoteMutation();

  useEffect(() => {
    setNote(box?.note || null);
  }, [box]);

  const handleChangeNote = useCallback((note: string) => {
    setNote(note);
  }, []);

  const currencySymbol = useGetCurrencySymbol();

  const handleUpdateBox = useCallback(async (): Promise<void> => {
    try {
      if (box) {
        await updateBoxNote({ boxId: box.boxId, note: note || null }).unwrap();

        showNotification('info', t('Success'), t('Box note has been successfully updated'));
        onClose();
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error'), t('Error when updating box note'));
    }
  }, [box, updateBoxNote, note, t, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={450}>
      <div className="flex flex-col gap-8 px-6">
        <div className="flex flex-col gap-3 px-2">
          <div>
            <div className="font-semibold text-2xl flex items-center gap-2">
              {box?.name}
              {box?.floorName && <span className="text-primaryLight"> — {getLocalizedString(box.floorName)}</span>}
            </div>
            {/* <Button theme="clear" icon={<MapIcon className="stroke-accent" />} iconPosition="prev">
              {t('View on the warehouse map')}
            </Button> */}
          </div>
          <div className="flex">
            <div className="flex-1">
              <div className="flex items-center">
                <SizeIcon className="mr-3 stroke-accent" />
                {box?.square} m<sup>2</sup>
              </div>
              {box?.height && box?.width && box?.length ? (
                <div className="flex items-center">
                  <SquareIcon className="w-6 h-6 mr-3 [&>path]:fill-accent" />
                  {roundNumber(box.height * box.width * box.length)} m<sup className="font-bold">3</sup>
                </div>
              ) : null}
              <div className="flex items-start mt-2">
                <WarehouseIcon className="mr-3 [&>path]:stroke-accent" />
                <div className="flex flex-col justify-start">
                  <div>
                    {box?.height}m<span className="font-normal text-primaryLight"> - {t('Height')}</span>
                  </div>
                  <div>
                    {box?.width}m<span className="font-normal text-primaryLight"> - {t('Width')}</span>
                  </div>
                  <div>
                    {box?.length}m<span className="font-normal text-primaryLight"> - {t('Length')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex items-center">
                <LockIcon className="w-6 h-6 mr-3 stroke-accent" />
                {box?.lockAddress}
              </div>
              <div className="flex items-start mt-2">
                <WalletIcon className="mr-3 stroke-accent" />
                <div className="flex flex-col justify-start">
                  {box?.dailyRate && (
                    <div>
                      {box.dailyRate} {currencySymbol}
                      <span className="font-normal text-primaryLight">/{t('Day')}</span>
                    </div>
                  )}
                  {box?.weekRate && (
                    <div>
                      {box.weekRate} {currencySymbol}
                      <span className="font-normal text-primaryLight">/{t('Week')}</span>
                    </div>
                  )}
                  {box?.monthRate && (
                    <div>
                      {box.monthRate} {currencySymbol}
                      <span className="font-normal text-primaryLight">/{t('Month')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{getLocalizedString(box?.description)}</div>
        <TextArea placeholder={t('Add your note...')} rows={4} bordered value={note} onChange={handleChangeNote} />
        <div className="mt-2 flex flex-col gap-3">
          <Button onClick={handleUpdateBox} isLoading={isLoading}>
            {t('Save Changes')}
          </Button>
          <Button theme="outlined" onClick={onClose}>
            {t('Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
