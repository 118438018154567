import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import { BoxForListing as Box, LockStatusMarker } from 'entities/Box';
import { ReactComponent as ContractsTableIcon } from 'shared/assets/icons/ContractsTableIcon.svg';
import { ReactComponent as LockIcon } from 'shared/assets/icons/LockIcon.svg';
import { Paragraph } from 'shared/ui/Paragraph';
import { Toggle } from 'shared/ui/Toggle';
import { getClientDateFormat, getClientDateWithTimeFormat } from 'shared/utils/helpers/getDateFormat';
import { useRole } from 'entities/User';

interface Args {
  isDesktop: boolean;
  t: TFunction;
  openBoxInfoModal: (box: Box) => void;
  currencySymbol: string;
  goToUserContracts: (userId: string | undefined) => void;
  openContractDetails: (contractId: string | undefined) => void;
  openReservationDetails: (reservationId: string | undefined) => void;
  toggleBoxActivation: (box: Box) => Promise<void>;
}

export const renderBoxListColumns = ({
  isDesktop,
  toggleBoxActivation,
  openBoxInfoModal,
  currencySymbol,
  openReservationDetails,
  openContractDetails,
  goToUserContracts,
  t,
}: Args): ColumnsType<Box> => {
  const { isManagement } = useRole();

  const mobileColumns: ColumnsType<Box> = [
    {
      title: t('Box'),
      width: '10%',
      key: 'name',
      render: (_, record) => (
        <div>
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              openBoxInfoModal(record);
            }}
          >
            {record.name}
          </div>
          {record.lockAddress && (
            <div className="flex items-center py-2">
              <LockIcon className="w-4 h-4 mr-1 stroke-accent" />
              <span className="text-primary text-xs">{record.lockAddress}</span>
            </div>
          )}
          <LockStatusMarker lockStatus={record.lockStatus} isExternalBox={record.timeToOpenLockMS != null} />
        </div>
      ),
    },
    {
      title: t('Square'),
      width: '10%',
      key: 'square',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (_, record) => (
        <div className="flex flex-col">
          <div className="text-sm">{record.warehouseName}</div>
          <div className="font-bold">
            {record.square}m<sup className="font-bold">2</sup>{' '}
          </div>
        </div>
      ),
    },
  ];

  const desktopColumns: ColumnsType<Box> = [
    {
      title: t('Cost'),
      width: '15%',
      key: 'monthRate',
      render: (_, record) => (
        <div className="text-primary">
          {record.dailyRate && (
            <div>
              {record.dailyRate} {currencySymbol}
              <span className="text-sm text-primaryLight">/{t('Day')}</span>
            </div>
          )}
          {record.weekRate && (
            <div>
              {record.weekRate} {currencySymbol}
              <span className="text-sm text-primaryLight">/{t('Week')}</span>
            </div>
          )}
          {record.monthRate && (
            <div>
              {record.monthRate} {currencySymbol}
              <span className="text-sm text-primaryLight">/{t('Month')}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: t('Customer'),
      key: 'user',
      width: '15%',
      render: (_, record) => (
        <div>
          <div className="flex gap-2 text-primary font-normal">
            {record?.userId && (
              <div>
                <div className="flex gap-2 text-primary font-normal">
                  {record.userEmail}
                  <ContractsTableIcon
                    className="cursor-pointer"
                    onClick={() => {
                      goToUserContracts(record.userId);
                    }}
                  />
                </div>
                {record?.contractId && (
                  <div className="text-primary font-normal">
                    {t('Contract')}{' '}
                    <span
                      className="text-accent underline cursor-pointer"
                      onClick={() => {
                        openContractDetails(record.contractId);
                      }}
                    >
                      {record.contractNumber}
                    </span>
                  </div>
                )}
                {record.reservationId && (
                  <div className="text-primary font-normal">
                    {t('Reservation')}{' '}
                    <span
                      className="text-accent underline cursor-pointer"
                      onClick={() => {
                        openReservationDetails(record.reservationId);
                      }}
                    >
                      {record.reservationNumber}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: t('Last opened'),
      key: 'lastOpened',
      width: '10%',
      render: (_, record) => <div className="text-primary font-normal">{getClientDateWithTimeFormat(record.lastOpened)}</div>,
    },
    {
      title: t('Renting info'),
      key: 'rentingInfo',
      width: '20%',
      render: (_, record) => (
        <>
          {record.contractId && (
            <>
              {record.isContractPaid ? (
                <div className="text-primary font-normal">
                  {t('Charged to')}: {getClientDateFormat(record.chargedTo)}
                  <div className="text-success">{t('Contract is paid')}</div>
                </div>
              ) : (
                <div className="text-primary font-normal">
                  {t('Charged to')}: {getClientDateFormat(record.chargedTo)}
                  <div className="text-error">{t('Contract is unpaid')}</div>
                </div>
              )}
            </>
          )}
          {record.expirationDate && record.reservationId && (
            <div className="text-primary font-normal">
              {t('Expiration date')}: <span className="text-warn font-normal">{getClientDateFormat(record.expirationDate)}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: t('Availability'),
      key: 'availability',
      width: '7%',
      render: (_, record) => (
        <div>
          <Toggle
            checked={record.isActive}
            isDisabled={!isManagement}
            onChange={async () => {
              await toggleBoxActivation(record);
            }}
          />
          <div className="text-xs text-primaryLight mt-2">{t(record.isActive ? 'Enabled' : 'Disabled')}</div>
        </div>
      ),
    },
    {
      title: t('Note'),
      key: 'note',
      width: '15%',
      render: (_, record) => (
        <Paragraph rows={2} className="text-primaryLight font-normal">
          {record.note}
        </Paragraph>
      ),
    },
  ];

  const resultColumns = !isDesktop ? mobileColumns : [...mobileColumns, ...desktopColumns];

  return resultColumns;
};
