export const swipeArrayItems = <T>(array: T[] | undefined, fromIndex: number, toIndex: number): T[] => {
  if (!array) {
    return [];
  }

  if (fromIndex < 0 || toIndex < 0 || fromIndex >= array.length || toIndex >= array.length) {
    console.error('Invalid index passed to swipeArrayItems()');

    return array;
  }

  [array[fromIndex], array[toIndex]] = [array[toIndex], array[fromIndex]];

  return array;
};
