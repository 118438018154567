import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as KeyIcon } from 'shared/assets/icons/KeyIcon.svg';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as BoxInfoIcon } from 'shared/assets/icons/BoxInfoIcon.svg';
import { ReactComponent as BoxEditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import {
  BoxForListing as Box,
  useActivateBoxMutation,
  useDeactivateBoxMutation,
  useGetPaginatedBoxListingQuery,
  useOnLockStatusChanged,
  BoxForListing,
} from 'entities/Box';
import { useGetCurrencySymbol } from 'app/appState';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useOpenBox } from 'features/OpenBox';
import { useTableFilterContext } from 'features/TableFilter';
import { TableRowFullInfoModal, useRowFullInfo } from 'features/TableRowFullInfoModal';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { boxFullInfoMapper } from '../utils/helpers/boxFullInfoMapper';
import { transformBoxesListFilters } from '../utils/helpers/transformBoxesListFilters';
import { BoxesListFilter } from './BoxesListFilter';
import { BoxInfoCard } from './BoxInfoCard';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ModalMode } from '../model/types';
import { BoxFormDrawer, DrawerMode, boxFormDrawerActions } from 'features/BoxFormDrawer';
import { ReservationDetailsDrawer, reservationDetailDrawerActions } from 'widgets/ReservationDetailsDrawer';
import { CancelReservationModal } from 'features/CancelReservationModal';
import { TakeUpReservationModal } from 'features/TakeUpReservationModal';
import { ExportEntity, ExportModal, getBoxColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useRole, UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { renderBoxListColumns } from './BoxListColumns';

export const BoxesTable: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'boxes', 'common']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isManagement } = useRole();

  const isForbiddenCreateBox = useCheckPermission(UserPermissions.CREATE_UNITS);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const [modalMode, setModalMode] = useState<Nullable<ModalMode>>(null);
  const [selectedBox, setSelectedBox] = useState<Nullable<Box>>(null);

  const { openExportModal } = useOpenExportModal();

  const { openFullInfoModal } = useRowFullInfo();

  const { isDesktop } = useScreenBreakpoints();

  const [activateBox] = useActivateBoxMutation();
  const [deactivateBox] = useDeactivateBoxMutation();

  const { executeBoxOpening } = useOpenBox();

  const newLockStatus = useOnLockStatusChanged();

  const goToUserContracts = useCallback(
    (userId: string | undefined): void => {
      if (userId) {
        navigate(`${AppRoutes.USERS}/${userId}/contracts`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (newLockStatus) {
      showNotification(
        'warning',
        t('Lock status change'),
        t('Box {{boxNumber}} (lock {{lockId}}) has change status to {{status}}', {
          boxNumber: newLockStatus.boxNumber,
          lockId: newLockStatus.lockId,
          status: newLockStatus.status,
        }),
      );
    }
  }, [newLockStatus, t]);

  useEffect(() => {
    // TO DO INVESTIGATE HOW TO CLEAR ALL FILTERS WITHOUT USE EFFECT !!!
    return () => {
      clearAllFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencySymbol = useGetCurrencySymbol();

  const closeModal = useCallback((): void => {
    setModalMode(null);
    setSelectedBox(null);
  }, []);

  const openModal = useCallback((mode: ModalMode): void => {
    setModalMode(mode);
  }, []);

  const openBoxInfoModal = useCallback(
    (box: Box, mode = ModalMode.BOX_INFO): void => {
      setSelectedBox(box);
      openModal(mode);
    },
    [openModal],
  );

  const createBox = useCallback((): void => {
    dispatch(boxFormDrawerActions.setOpenBoxForm({ mode: DrawerMode.BOX_CREATE }));
  }, [dispatch]);

  const editBox = useCallback(
    (box: Box): void => {
      dispatch(boxFormDrawerActions.setOpenBoxForm({ mode: DrawerMode.BOX_EDIT, box }));
    },
    [dispatch],
  );

  const openBox = useCallback(
    (box: Box): void => {
      executeBoxOpening({
        box,
        openByAdmin: true,
        isExternalBox: box.timeToOpenLockMS != null,
      });
    },
    [executeBoxOpening],
  );

  const toggleBoxActivation = useCallback(
    async (box: Box) => {
      try {
        if (box.isActive) {
          await deactivateBox(box.boxId).unwrap(); // TODO ADD CONFIRM + ADD TOGGLE BOX ACTIVATION API
          showNotification('info', t('Success'), t('Box has been successfully deactivated'));
        } else {
          await activateBox(box.boxId).unwrap();
          showNotification('info', t('Success'), t('Box has been successfully activated'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), 'Error when changing box status');
      }
    },
    [activateBox, deactivateBox, t],
  );

  const openContractDetails = useCallback(
    (contractId: string | undefined): void => {
      if (contractId) {
        dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
      }
    },
    [dispatch],
  );

  const openReservationDetails = useCallback(
    (reservationId: string | undefined): void => {
      if (reservationId) {
        dispatch(reservationDetailDrawerActions.setOpenReservationDetails(reservationId));
      }
    },
    [dispatch],
  );

  const rowActions: Array<ServerTableRowActions<Box>> = useMemo(
    () => [
      {
        name: 'boxHistory',
        icon: <BoxInfoIcon />,
        theme: 'clear',
        description: t('Box history', { ns: 'boxes' }),
        onClick: (selectedBox) => {
          console.log(selectedBox);
        },
      },
      {
        name: 'editBox',
        icon: <BoxEditIcon />,
        theme: 'clear',
        description: t('Edit box'),
        onClick: editBox,
      },
      {
        name: 'deleteBox',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete box'),
        onClick: (selectedBox) => {
          console.log(selectedBox);
        },
      },
      {
        name: 'openBox',
        theme: 'primary',
        label: t('Open'),
        icon: <KeyIcon className="stroke-white" />,
        iconPosition: 'prev',
        onClick: openBox,
      },
    ],
    [editBox, openBox, t],
  );

  const tableActions: Array<ServerTableActions<Box>> = useMemo(
    () => [
      {
        name: t('Add Box'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createBox,
        disabled: isForbiddenCreateBox,
      },
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformBoxesListFilters(appliedFilters),
            columns: getBoxColumnKeys(t),
            entity: ExportEntity.BOX,
          });
        },
      },
      {
        name: t('Filters', { ns: 'boxes' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [t, createBox, isForbiddenCreateBox, openExportModal, appliedFilters, setFiltersOpened],
  );

  const staffRowActions = rowActions.filter((row) => row.name === 'openBox');

  const openBoxFullInfoModal = useCallback(
    (data: AnyObject): void => {
      const { title, rows, status, note } = boxFullInfoMapper(data as BoxForListing, currencySymbol, t);

      openFullInfoModal(title, status, rows, note);
    },
    [currencySymbol, openFullInfoModal, t],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Boxes List')}</div>
      <ServerTable
        rowActions={isManagement ? rowActions : staffRowActions}
        columns={renderBoxListColumns({
          isDesktop,
          t,
          openBoxInfoModal,
          currencySymbol,
          goToUserContracts,
          openContractDetails,
          openReservationDetails,
          toggleBoxActivation,
        })}
        fetchData={useGetPaginatedBoxListingQuery}
        defaultFilters={transformBoxesListFilters(appliedFilters)}
        rowKey="boxId"
        search
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        tableActions={isManagement ? tableActions : undefined}
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter box, customer info or lock number')}
        onRowClick={!isDesktop ? openBoxFullInfoModal : undefined}
      />
      <BoxInfoCard isOpen={modalMode === ModalMode.BOX_INFO} onClose={closeModal} box={selectedBox} />
      <ContractDetailsDrawer />
      <ReservationDetailsDrawer />
      <BoxFormDrawer />
      <CancelReservationModal />
      <TakeUpReservationModal />
      <ExportModal />
      <BoxesListFilter />
      {!isDesktop && <TableRowFullInfoModal />}
    </div>
  );
});
