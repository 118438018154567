import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenderPDFSchema } from '../types';

const initialState: RenderPDFSchema = {
  base64string: null,
};

const renderPDFSlice = createSlice({
  name: 'renderPDF',
  initialState,
  reducers: {
    openModal: (state: RenderPDFSchema, action: PayloadAction<Nullable<string>>) => {
      state.base64string = action.payload;
    },
    closeModal: (state: RenderPDFSchema) => {
      state.base64string = null;
    },
  },
});

export const { actions: renderPDFActions, reducer: renderPDFReducer } = renderPDFSlice;
