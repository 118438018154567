import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData } from '../../model/selectors/getLoggedUserData';
import { UserRoles } from 'entities/User';

export const useRole = (): {
  isAdmin: boolean;
  isManager: boolean;
  isStaff: boolean;
  isManagement: boolean;
} => {
  const user = useAppSelector(getLoggedUserData);

  if (!user) {
    return {
      isAdmin: false,
      isManager: false,
      isStaff: false,
      isManagement: false,
    };
  }

  const { role } = user;

  return {
    isAdmin: role === UserRoles.SUPERUSER,
    isManager: role === UserRoles.MANAGER,
    isStaff: role === UserRoles.STAFF,
    isManagement: [UserRoles.SUPERUSER, UserRoles.MANAGER].includes(role),
  };
};
