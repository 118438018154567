import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';

export interface TableRow {
  label: string | ReactNode;
  value: Nullable<string | number | ReactNode> | undefined;
  highlighted?: boolean;
  emptyValueSymbol?: string;
  onClick?: () => void;
  color?: string;
}

type BulletsTableTheme = 'default' | 'clear';

interface BulletsTableProps {
  rows: TableRow[];
  theme?: BulletsTableTheme;
  heading?: string;
  className?: string;
  labelClassName?: string;
}

export const BulletsTable: FC<BulletsTableProps> = memo((props) => {
  const { rows, heading, theme = 'default', className, labelClassName = '' } = props;

  return (
    <div className={classNames('w-full', className)}>
      <div className="flex px-3 py-2 text-accent font-semibold text-base">{heading}</div>
      {rows.map(({ label, value, highlighted, emptyValueSymbol = '-', color, onClick }, index) => {
        const isEvenRow = (index + 1) % 2 === 0;

        const themesMap: Record<BulletsTableTheme, string> = {
          default: isEvenRow ? 'bg-secondaryLight' : 'bg-white',
          clear: 'bg-white',
        };

        const rowValueClass = classNames('font-normal', {
          'font-semibold': highlighted,
          'font-semibold cursor-pointer text-accent underline': value && onClick,
          [color as string]: Boolean(color),
        });

        return (
          <div key={index} className={`flex justify-between px-5 py-2 ${themesMap[theme]}`}>
            <div
              className={`${
                highlighted ? `font-semibold ${color || 'text-primary'}` : `font-normal ${color || 'text-primaryLight'}`
              } ${labelClassName}`}
            >
              {label}
            </div>
            <div onClick={onClick} className={rowValueClass}>
              {value ?? emptyValueSymbol}
            </div>
          </div>
        );
      })}
    </div>
  );
});
