import React, { FC, ReactElement, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import {
  Contract,
  useDisableAutochargesMutation,
  usePauseAutochargesMutation,
  useResumeAutochargesMutation,
  AutochargeStatus,
} from 'entities/Contract';
import { showNotification } from 'app/providers/NotificationsProvider';
import { WarnTag } from 'shared/ui/WarnTag';
import classNames from 'classnames';
import { useRole, UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface AutochargeSettingsProps {
  contract: Contract;
  action: ReactElement;
}

export const AutochargeSettings: FC<AutochargeSettingsProps> = memo((props) => {
  const { contract, action } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { t } = useAppTranslation(['contracts', 'common']);

  const [disableAutocharges, { isLoading: isDisableLoading }] = useDisableAutochargesMutation();
  const [pauseAutocharges, { isLoading: isPauseLoading }] = usePauseAutochargesMutation();
  const [resumeAutocharges, { isLoading: isResumeLoading }] = useResumeAutochargesMutation();

  const isForbiddenManageAutocharges = useCheckPermission(UserPermissions.MANAGE_AUTOCHARGES);

  const { isManager } = useRole();

  const isForbidden = isManager && isForbiddenManageAutocharges;

  const isLoading = useMemo(
    () => isDisableLoading || isPauseLoading || isResumeLoading,
    [isDisableLoading, isPauseLoading, isResumeLoading],
  );

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const autochargesTextColor = useMemo(
    () => (contract.autocharges === AutochargeStatus.ENABLED ? 'text-success' : 'text-warn'),
    [contract],
  );

  const handleDisableAutocharges = useCallback(async (): Promise<void> => {
    try {
      if (contract) {
        await disableAutocharges(contract.contractId).unwrap();

        showNotification('info', t('Success'), t('Autocharges disabled successfully', { ns: 'common' }));
        closeModal();
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error'), t('Error while autocharges disable'));
      console.log('error', error);
    }
  }, [contract, disableAutocharges, t, closeModal]);

  const handlePauseAutocharges = useCallback(async (): Promise<void> => {
    try {
      if (contract) {
        await pauseAutocharges(contract.contractId).unwrap();

        showNotification('info', t('Success'), t('Autocharges paused successfully', { ns: 'common' }));
        closeModal();
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error'), t('Error while autocharges pausing'));
      console.log('error', error);
    }
  }, [contract, pauseAutocharges, t, closeModal]);

  const handleResumeAutocharges = useCallback(async (): Promise<void> => {
    try {
      if (contract) {
        await resumeAutocharges(contract.contractId).unwrap();

        showNotification('info', t('Success'), t('Autocharges resumed successfully', { ns: 'common' }));
        closeModal();
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error'), t('Error while autocharges resuming'));
      console.log('error', error);
    }
  }, [contract, resumeAutocharges, t, closeModal]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[450px]" isOpen={isOpenedModal} onClose={closeModal}>
        <div className="font-semibold text-xl mb-3">{t('Autocharge settings')}</div>
        <div className="text-primaryLight font-normal">
          {t('Contract {{contractNumber}} autocharges are cuttently', { contractNumber: contract?.contractNumber })}
          <span className={classNames(autochargesTextColor, 'font-semibold')}> {t(contract.autocharges)}</span>
        </div>
        <WarnTag className="my-3" textSize="medium">
          {t('Attention! Disabling contract autocharges does not close contract')}
        </WarnTag>

        <div className="flex justify-end gap-3">
          {contract.autocharges === AutochargeStatus.ENABLED ? (
            <Button isLoading={isLoading} theme="warn" onClick={handlePauseAutocharges} isDisabled={isForbidden}>
              {t('Pause', { ns: 'common' })}
            </Button>
          ) : (
            <Button isLoading={isLoading} theme="success" onClick={handleResumeAutocharges} isDisabled={isForbidden}>
              {t('Resume', { ns: 'common' })}
            </Button>
          )}

          <Button isLoading={isLoading} theme="danger" onClick={handleDisableAutocharges} isDisabled={isForbidden}>
            {t('Disable', { ns: 'common' })}
          </Button>

          <Button isLoading={isLoading} theme="secondary" onClick={closeModal}>
            {t('Cancel', { ns: 'common' })}
          </Button>
        </div>
      </Modal>
    </>
  );
});
