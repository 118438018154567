import React, { FC, memo } from 'react';
import { Modal } from 'shared/ui/Modal';
import { useRenderPDFModal } from '../utils/hooks/useRenderPDFModal';

export const RenderPDFModal: FC = memo(() => {
  const { isOpened, base64String, closeRenderPDFModal } = useRenderPDFModal();

  const src = `data:application/pdf;base64,${base64String}`;

  return (
    <Modal width={1100} isOpen={isOpened} onClose={closeRenderPDFModal}>
      <iframe className="mt-8 w-full h-[calc(100vh-100px)]" src={src} />
    </Modal>
  );
});
