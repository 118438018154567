import React, { FC, memo, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { NotesListFilterKeys } from '../../model/types';
import { NoteTypeSelect } from './NoteTypeSelect';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { AssignedUsersSelect } from './AssignedUsersSelect';

export const NotesListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<NotesListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: NotesListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  const changeDeadlineDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: NotesListFilterKeys.deadlineDateFrom,
        label: t('From {{deadlineDateFrom}}', { deadlineDateFrom: clientFormatDateFrom }),
        value,
        title: t('Deadline date'),
      });
    },
    [changeFilters, t],
  );

  const changeDeadlineDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: NotesListFilterKeys.deadlineDateTo,
        label: t('To {{deadlineDateTo}}', { deadlineDateTo: clientFormatDateTo }),
        value,
        title: t('Deadline date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: NotesListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: NotesListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeNoteType = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: NotesListFilterKeys.noteType, label, value, title: t('Note type') });
    },
    [changeFilters, t],
  );

  const changeAssignedUsers = useCallback(
    (value: string[]) => {
      changeFilters({
        key: NotesListFilterKeys.assignedUsers,
        label: `${t('Selected')}: ${value.length}`,
        value,
        title: t('Assigned to'),
      });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Deadline range')}
          onChangeFrom={changeDeadlineDateFrom}
          onChangeTo={changeDeadlineDateTo}
          values={[
            filters.deadlineDateFrom?.value && dayjs(filters.deadlineDateFrom?.value),
            filters.deadlineDateTo?.value && dayjs(filters.deadlineDateTo?.value),
          ]}
        />

        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />

        <AssignedUsersSelect value={filters.assignedUsers?.value} onChange={changeAssignedUsers} onClear={clearCurrentFilters} />

        <NoteTypeSelect value={filters.noteType?.value} onChange={changeNoteType} onClear={clearCurrentFilters} />
      </div>
    </TableFilter>
  );
});
