import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Divider, Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { InputNumber } from 'shared/ui/InputNumber';
import { Select } from 'shared/ui/Select';
import { useGetCurrencySymbol } from 'app/appState';
import { useUpdateInsuranceMutation, useCreateInsuranceMutation, Insurance } from 'entities/Insurance';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import { showNotification } from 'app/providers/NotificationsProvider';
import { InsuranceFormFields, DrawerMode } from '../model/types';
import { UserPermissions } from 'entities/User';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';

interface InsuranceFormProps {
  insurance?: Nullable<Insurance>;
  mode: Nullable<DrawerMode>;
  handleClose: () => void;
}

export const InsuranceForm: FC<InsuranceFormProps> = memo((props) => {
  const { insurance, mode, handleClose } = props;
  const { t } = useAppTranslation(['boxes', 'common']);

  const [form] = Form.useForm();

  const currencySymbol = useGetCurrencySymbol();

  const isForbiddenEdit = useCheckPermission(UserPermissions.EDIT_UNITS);

  const [createInsurance, { isLoading: isCreationLoading }] = useCreateInsuranceMutation();
  const [updateInsurance, { isLoading: isUpdationLoading }] = useUpdateInsuranceMutation();
  const { data: warehouses } = useGetAllWarehousesQuery();

  const isLoading = isUpdationLoading || isCreationLoading;

  const warehouseOptions = useMemo(() => {
    return (
      warehouses?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [warehouses]);

  useEffect(() => {
    insurance ? form.setFieldsValue(insurance) : form.resetFields();
  }, [form, insurance]);

  const handleSubmit = useCallback(
    async (data: InsuranceFormFields): Promise<void> => {
      try {
        switch (mode) {
          case DrawerMode.INSURANCE_CREATE:
            await createInsurance(data).unwrap();
            showNotification('info', t('Success'), t('Insurance has been successfully created'));
            break;
          case DrawerMode.INSURANCE_EDIT:
            if (insurance) {
              const { insuranceId } = insurance;
              await updateInsurance({
                ...data,
                insuranceId,
              }).unwrap();
              showNotification('info', t('Success'), t('Insurance has been successfully updated'));
            }
            break;
        }

        /* form.resetFields();

        handleClose(); */
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t(`Error when ${mode === DrawerMode.INSURANCE_CREATE ? 'creating' : 'updating'} insurance`));
      }
    },
    [mode, createInsurance, t, insurance, updateInsurance],
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col px-10 py-4" disabled={isForbiddenEdit}>
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-3xl">
          {mode === DrawerMode.INSURANCE_EDIT ? `${t('Edit')} ${insurance?.name}` : t('Create insurance')}
        </div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={handleClose}>
          {t('Close')}
        </div>
      </div>

      <Form.Item
        label={t('Insurance name')}
        name="name"
        rules={[
          { required: true, message: `${t('Please, enter insurance name')}!` },
          {
            min: 2,
            message: t('Insurance name must have more than 2 characters!'),
          },
        ]}
      >
        <Input placeholder={t('Please, enter insurance name')} bordered />
      </Form.Item>
      <Form.Item label={t('Warehouse')} name="warehouseId" className="flex-1">
        <Select
          options={warehouseOptions}
          placeholder={t('Please, select warehouse')}
          bordered
          allowClear
          disabled={mode === DrawerMode.INSURANCE_EDIT}
        />
      </Form.Item>

      <Divider className="mb-4 mt-0" />

      <div className="flex gap-4 mb-0">
        <Form.Item label={`${t('Daily rate')}/${currencySymbol}`} name="dailyRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Daily rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Week rate')}/${currencySymbol}`} name="weekRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Week rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Month rate')}/${currencySymbol}`} name="monthRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Month rate')} bordered />
        </Form.Item>
      </div>
      <Form.Item
        className="my-0 text-center"
        validateStatus="error"
        help={form.getFieldError(['dailyRate', 'weekRate', 'monthRate'])?.join(' ') || ''}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.dailyRate !== currentValues.dailyRate ||
          prevValues.weekRate !== currentValues.weekRate ||
          prevValues.monthRate !== currentValues.monthRate
        }
      >
        {({ getFieldValue }) => {
          const dailyRate = getFieldValue('dailyRate');
          const weekRate = getFieldValue('weekRate');
          const monthRate = getFieldValue('monthRate');

          if (!dailyRate && !weekRate && !monthRate) {
            return <span className="my-0 text-error">{t('Please fill in at least one of the rates!')}</span>;
          }

          return null;
        }}
      </Form.Item>

      <Divider className="mb-6 mt-0" />

      <div className="flex gap-4">
        <Form.Item
          label={t('Coverage amount')}
          name="coverageAmount"
          className="flex-1"
          rules={[{ required: true, message: `${t('Please, enter coverage amount')}!` }]}
        >
          <InputNumber placeholder={t('Coverage amount')} bordered />
        </Form.Item>
        <Form.Item label={t('Insurers price')} name="insurerPrice" className="flex-1">
          <InputNumber placeholder={t('Insurers price')} bordered />
        </Form.Item>
      </div>

      <Button type="submit" isLoading={isLoading} isDisabled={isForbiddenEdit}>
        {t('Save')}
      </Button>
    </Form>
  );
});
