import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { SPECIAL_PROMOTION_MODAL_KEY } from '../../utils/constants';
import { SpecialPromotionModalSchema } from '../types';

const initialState: SpecialPromotionModalSchema = {
  Content: null,
};

const specialPromotionModalSlice = createSlice({
  name: 'specialPromotionModal',
  initialState,
  reducers: {
    openModal: (state: SpecialPromotionModalSchema, action: PayloadAction<ComponentType>) => {
      state.Content = action.payload;
    },
    initModal: (state: SpecialPromotionModalSchema, action: PayloadAction<ComponentType>) => {
      state.Content = action.payload;

      const localModalState = localStorage.getItem(SPECIAL_PROMOTION_MODAL_KEY);

      if (!localModalState) {
        localStorage.setItem(SPECIAL_PROMOTION_MODAL_KEY, 'true');
      }
    },
    closeModal: (state: SpecialPromotionModalSchema) => {
      const localModalState = localStorage.getItem(SPECIAL_PROMOTION_MODAL_KEY);

      if (localModalState === 'true') {
        localStorage.setItem(SPECIAL_PROMOTION_MODAL_KEY, 'false');
      }

      state.Content = null;
    },
  },
});

export const { actions: specialPromotionModalActions, reducer: specialPromotionModalReducer } = specialPromotionModalSlice;
