import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Divider, Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { InputNumber } from 'shared/ui/InputNumber';
import { Select } from 'shared/ui/Select';
import { useGetCurrencySymbol } from 'app/appState';
import { TextArea } from 'shared/ui/TextArea';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import { SizeCodeForListing, SizeCodeType, SizeGroup, useCreateSizeCodeMutation, useUpdateSizeCodeMutation } from 'entities/SizeCode';
import { showNotification } from 'app/providers/NotificationsProvider';
import { validateLocalizedJSON } from 'shared/utils/helpers/JSONLocalization';
import { DrawerMode } from '../model/types';
import { useCheckPermission } from 'shared/utils/hooks/useCheckPermission';
import { UserPermissions } from 'entities/User';

interface SizeCodeFormProps {
  sizeCode?: Nullable<SizeCodeForListing>;
  mode: Nullable<DrawerMode>;
  handleClose: () => void;
}

export const SizeCodeForm: FC<SizeCodeFormProps> = memo((props) => {
  const { sizeCode, mode, handleClose } = props;
  const { t } = useAppTranslation(['boxes', 'common']);

  const [form] = Form.useForm();

  const currencySymbol = useGetCurrencySymbol();

  const isForbidden = useCheckPermission(UserPermissions.EDIT_UNITS);

  const [createSizeCode, { isLoading: isCreationLoading }] = useCreateSizeCodeMutation();
  const [updateSizeCode, { isLoading: isUpdationLoading }] = useUpdateSizeCodeMutation();

  const { data: warehouses } = useGetAllWarehousesQuery();

  const isLoading = isUpdationLoading || isCreationLoading;

  const warehouseOptions = useMemo(() => {
    return (
      warehouses?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [warehouses]);

  const sizeCodeTypeOptions = Object.values(SizeCodeType).map((type) => ({
    label: type,
    value: type,
  }));

  const sizeCodeGroupOptions = Object.values(SizeGroup).map((group) => ({
    label: group,
    value: group,
  }));

  useEffect(() => {
    sizeCode ? form.setFieldsValue(sizeCode) : form.resetFields();
  }, [form, sizeCode]);

  const handleSubmit = useCallback(
    async (data: SizeCodeForListing): Promise<void> => {
      try {
        switch (mode) {
          case DrawerMode.SIZE_CODE_CREATE:
            await createSizeCode(data).unwrap();
            showNotification('info', t('Success'), t('Size code has been successfully created'));
            break;
          case DrawerMode.SIZE_CODE_EDIT:
            if (sizeCode) {
              const { sizeCodeId } = sizeCode;
              await updateSizeCode({
                ...data,
                sizeCodeId,
              }).unwrap();
              showNotification('info', t('Success'), t('Size code has been successfully updated'));
            }
            break;
        }

        /* form.resetFields();

        handleClose(); */
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t(`Error when ${mode === DrawerMode.SIZE_CODE_CREATE ? 'creating' : 'updating'} sizeCode`));
      }
    },
    [createSizeCode, mode, sizeCode, t, updateSizeCode],
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col px-10 py-4" disabled={isForbidden}>
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-3xl">
          {mode === DrawerMode.SIZE_CODE_EDIT ? `${t('Edit')} ${sizeCode?.square}` : t('Create sizeCode')}
        </div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={handleClose}>
          {t('Close')}
        </div>
      </div>

      <Form.Item
        label={t('Warehouse')}
        name="warehouseId"
        rules={[{ required: true, message: `${t('Please, select warehouse')}!` }]}
        className="flex-1"
      >
        <Select
          options={warehouseOptions}
          placeholder={t('Please, select warehouse')}
          bordered
          disabled={mode === DrawerMode.SIZE_CODE_EDIT}
        />
      </Form.Item>
      <div className="flex gap-4">
        <Form.Item
          label={t('Size code type')}
          rules={[{ required: true, message: `${t('Please, select size code type')}!` }]}
          className="flex-1"
          name="sizeCodeType"
        >
          <Select options={sizeCodeTypeOptions} placeholder={t('Size code type')} bordered />
        </Form.Item>

        <Form.Item
          label={t('Size group')}
          rules={[{ required: true, message: `${t('Please, select size group')}!` }]}
          className="flex-1"
          name="sizeGroup"
        >
          <Select options={sizeCodeGroupOptions} placeholder={t('Size group')} bordered />
        </Form.Item>
      </div>

      <Form.Item
        label={t('Square')}
        name="square"
        className="flex-1"
        rules={[{ required: true, message: `${t('Please, enter square')}!` }]}
      >
        <InputNumber placeholder={t('Square')} bordered isDisabled={mode === DrawerMode.SIZE_CODE_EDIT} />
      </Form.Item>

      <Divider className="mb-4 mt-0" />
      <div className="flex gap-4 mb-0">
        <Form.Item label={`${t('Daily rate')}/${currencySymbol}`} name="dailyRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Daily rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Week rate')}/${currencySymbol}`} name="weekRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Week rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Month rate')}/${currencySymbol}`} name="monthRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Month rate')} bordered />
        </Form.Item>
      </div>
      <Form.Item
        className="my-0 text-center"
        validateStatus="error"
        help={form.getFieldError(['dailyRate', 'weekRate', 'monthRate'])?.join(' ') || ''}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.dailyRate !== currentValues.dailyRate ||
          prevValues.weekRate !== currentValues.weekRate ||
          prevValues.monthRate !== currentValues.monthRate
        }
      >
        {({ getFieldValue }) => {
          const dailyRate = getFieldValue('dailyRate');
          const weekRate = getFieldValue('weekRate');
          const monthRate = getFieldValue('monthRate');

          if (!dailyRate && !weekRate && !monthRate) {
            return <span className="my-0 text-error">{t('Please fill in at least one of the rates!')}</span>;
          }

          return null;
        }}
      </Form.Item>

      <Divider className="mb-6 mt-0" />

      <Form.Item
        label={t('Booking Description')}
        name="description"
        rules={[
          () => ({
            async validator(_, value) {
              const isValidDescription = validateLocalizedJSON(value);

              if (!value || isValidDescription) {
                await Promise.resolve();
                return;
              }
              return await Promise.reject(new Error(t('Description must be a valid localized JSON')));
            },
          }),
        ]}
      >
        <TextArea placeholder={t('Add description...')} bordered rows={3} />
      </Form.Item>

      <Form.Item
        label={t('Size Guide Description')}
        name="extendedDescription"
        rules={[
          () => ({
            async validator(_, value) {
              const isValidDescription = validateLocalizedJSON(value);

              if (!value || isValidDescription) {
                await Promise.resolve();
                return;
              }
              return await Promise.reject(new Error(t('Description must be a valid localized JSON')));
            },
          }),
        ]}
      >
        <TextArea placeholder={t('Add description...')} bordered rows={3} />
      </Form.Item>

      <Button type="submit" isLoading={isLoading} isDisabled={isForbidden}>
        {t('Save')}
      </Button>
    </Form>
  );
});
