export enum InvoiceTypeFilter {
  CONTRACT = 'contract',
  PERSONAL = 'personal',
}

export enum InvoicesListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  isActive = 'isActive',
  invoiceType = 'invoiceType',
  invoiceAmount = 'invoiceAmount',
  invoicingDateFrom = 'invoicingDateFrom',
  invoicingDateTo = 'invoicingDateTo',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  dueDateFrom = 'dueDateFrom',
  dueDateTo = 'dueDateTo',
  status = 'status',
  invoiceNumberFrom = 'invoiceNumberFrom',
  invoiceNumberTo = 'invoiceNumberTo',
}
