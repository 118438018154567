import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData, useRole, UserPermissions } from 'entities/User';
import { useMemo } from 'react';

export const useCheckPermission = (requiredPermission: UserPermissions): boolean => {
  const { isAdmin, isManager, isStaff } = useRole();

  const user = useAppSelector(getLoggedUserData);

  const isForbidden = useMemo(() => {
    if (isAdmin) {
      return false;
    }

    if (isManager || isStaff) {
      return !user?.permissions?.includes(requiredPermission);
    }

    return true;
  }, [isAdmin, isManager, isStaff, user?.permissions, requiredPermission]);

  return isForbidden;
};
