import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { usePayInvoiceMutation } from 'entities/Invoice/api/payInvoiceApi';
import { PaymentProcessor } from 'entities/Payment';
import { Button } from 'shared/ui/Button';
import { CollapseRadioButtonGroup } from 'shared/ui/CollapseRadioButtonGroup';
import { Checkbox } from 'shared/ui/Checkbox';
import { Modal } from 'shared/ui/Modal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { ReactComponent as VisaIcon } from 'shared/assets/icons/VisaIcon.svg';
import { ReactComponent as MastercardIcon } from 'shared/assets/icons/MastercardIcon.svg';
import { ReactComponent as AmexIcon } from 'shared/assets/icons/AmexIcon.svg';
import { ReactComponent as GoogleIcon } from 'shared/assets/icons/GoogleIcon.svg';
import { ReactComponent as AppleIcon } from 'shared/assets/icons/AppleIcon.svg';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useNavigate } from 'react-router-dom';
import { Invoice, useBalancePay } from 'entities/Invoice';
import { AutochargeStatus } from 'entities/Contract';
import { InfoTag } from 'shared/ui/InfoTag';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useGetCurrencySymbol } from 'app/appState';

interface PaymentModalProps {
  invoice: Invoice;
  isOpen: boolean;
  onClose: () => void;
}

export const PaymentModal: FC<PaymentModalProps> = memo((props) => {
  const { invoice, isOpen, onClose } = props;

  const navigate = useNavigate();

  const { t } = useAppTranslation(['booking', 'common']);

  const currencySymbol = useGetCurrencySymbol();

  const [processor, setProcessor] = useState(PaymentProcessor.STRIPE);
  const [isAutoPaymentEnabled, setAutoPaymentEnabled] = useState(false);
  const [useUserBalance, setUseUserBalance] = useState(false);

  const { user } = invoice;

  const { isBalancePayAvaliable, amountToCharge } = useBalancePay(invoice);

  const [payInvoice] = usePayInvoiceMutation();

  const isAutochargesAllowed = useMemo(
    () => invoice?.contract?.autocharges === AutochargeStatus.DISABLED,
    [invoice?.contract?.autocharges],
  );

  const amountToPay = useMemo(
    () => (useUserBalance ? roundNumber(invoice.debt - amountToCharge) : invoice.debt),
    [amountToCharge, invoice.debt, useUserBalance],
  );

  useEffect(() => {
    const watchAutoPaymentStatus = (): void => {
      switch (processor) {
        case PaymentProcessor.STRIPE:
          setAutoPaymentEnabled(false);
          break;
        case PaymentProcessor.PAYPAL:
          setAutoPaymentEnabled(false);
          break;
        case PaymentProcessor.SEPA:
          setAutoPaymentEnabled(true);
          break;
        case PaymentProcessor.BANK_TRANSFER:
          setAutoPaymentEnabled(false);
          break;
      }
    };

    watchAutoPaymentStatus();
  }, [processor]);

  const toggleAutoPayment = useCallback((): void => {
    setAutoPaymentEnabled((prevState) => !prevState);
  }, []);

  const toggleUseUserBalance = useCallback((): void => {
    setUseUserBalance((prevState) => !prevState);
  }, []);

  const handlePayInvoice = useCallback(async (): Promise<void> => {
    if (invoice) {
      try {
        const response = await payInvoice({
          invoiceId: invoice.invoiceId,
          processor,
          isAutopayment: isAutoPaymentEnabled,
          useUserBalance,
        }).unwrap();

        if (response.redirectionUrl) {
          if (response?.redirectionUrl?.includes(PaymentProcessor.STRIPE || PaymentProcessor.SEPA)) {
            const query = response?.redirectionUrl.split('?')[1];
            navigate(`${AppRoutes.CHECKOUT}?${query}`);
          } else {
            window.location.href = response.redirectionUrl;
          }
        } else {
          showNotification('info', t('Success'), t('Invoice has been successfully paid'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when paying invoice'));
      } finally {
        onClose();
      }
    }
  }, [invoice, payInvoice, processor, isAutoPaymentEnabled, useUserBalance, navigate, t, onClose]);

  const options = useMemo(
    () => [
      // TODO получать из отдельного запроса (бэк не готов)
      {
        label: (
          <div className="flex flex-col items-start justify-between w-full desktop:flex-row desktop:items-center">
            <span>{t('Credit/Debit card')}</span>
            <div className="flex flex-wrap items-center gap-2">
              <AmexIcon className="w-10 h-10" />
              <VisaIcon className="w-10 h-10" />
              <MastercardIcon className="w-10 h-10" />
              <AppleIcon className="w-7 h-7" />
              <GoogleIcon className="w-7 h-7" />
            </div>
          </div>
        ),
        value: PaymentProcessor.STRIPE,
        content: (
          <div>
            <div className="mb-4 text-primaryLight">
              {t('We accept all major credit and debit cards, including Visa, Mastercard, American Express, GooglePay and ApplePay.')}{' '}
              <br />
            </div>
            {isAutochargesAllowed && (
              <>
                <div className="font-semibold text-lg">{t('Auto pay')}</div>
                <div className="mb-2">
                  {t('Turn on the automatic payment feature so you don`t miss payments. You can turn it off at any time!')}
                </div>
                <Checkbox
                  name="isAutopayment"
                  checked={isAutoPaymentEnabled}
                  onChange={toggleAutoPayment} /* disabled={isPrePaymentApplied} */
                >
                  {t('Allow autopayment')}
                </Checkbox>
              </>
            )}
          </div>
        ),
      },
    ],
    [isAutoPaymentEnabled, isAutochargesAllowed, t, toggleAutoPayment],
  );

  const actions = useCallback((): JSX.Element => {
    return (
      <div className="flex space-x-3 justify-end">
        <Button theme="secondary" onClick={onClose}>
          {t('Cancel', { ns: 'common' })}
        </Button>
        <Button onClick={handlePayInvoice}>
          {amountToPay > 0 ? t('Pay {{amountToPay}} {{currencySymbol}}', { ns: 'common', amountToPay, currencySymbol }) : t('Continue')}
        </Button>
      </div>
    );
  }, [onClose, t, handlePayInvoice, amountToPay, currencySymbol]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} footer={actions()}>
      <div className="min-h-[350px]">
        <div className="font-semibold text-3xl mb-6">{t('Select payment method')}</div>
        {isBalancePayAvaliable && (
          <div className="mt-2 mb-6">
            <InfoTag textSize="medium">
              {t(
                'You have {{balance}} {{currencySymbol}} on your balance, for this Invoice you can charge {{amountToCharge}} {{currencySymbol}} from your balance',
                {
                  balance: user.balance,
                  currencySymbol,
                  amountToCharge,
                },
              )}
            </InfoTag>
            <Checkbox className="mt-3" name="useUserBalance" checked={useUserBalance} onChange={toggleUseUserBalance}>
              <span className="text-m">
                {t('Use {{amountToCharge}} {{currencySymbol}} from balance', { amountToCharge, currencySymbol })}
              </span>
            </Checkbox>
          </div>
        )}
        <CollapseRadioButtonGroup options={options} selectedOption={processor} setSelectedOption={setProcessor} />
      </div>
    </Modal>
  );
});
