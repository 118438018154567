import { useEffect } from 'react';
import { setGoogleClickIdToCookie } from '../helpers/setGoogleClickIdToCookie';
import { setGoogleClickIdDateToCookie } from '../helpers/setGoogleClickIdDateToCookie';

export const useInitGoogleClickId = (): void => {
  useEffect(() => {
    setGoogleClickIdToCookie();
    setGoogleClickIdDateToCookie();
  }, []);
};
