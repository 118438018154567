import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRoles, CreateUserParams, UserPermissions } from 'entities/User';
import { CreateUserModalSchema, createUserSteps } from '../types';

const initialState: CreateUserModalSchema = {
  isOpened: false,
  steps: createUserSteps,
  selectedRole: null,
  userData: null,
  selectedWarehouses: [],
  selectedPermissions: [],
};

const createUserModalSlice = createSlice({
  name: 'createUserModal',
  initialState,
  reducers: {
    setOpened: (state: CreateUserModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeStepData: (state: CreateUserModalSchema, action: PayloadAction<{ targetStepIndex: number; stepData: string }>) => {
      state.steps = state.steps.map((step, index) =>
        index === action.payload.targetStepIndex ? { ...step, stepData: action.payload.stepData } : step,
      );
    },
    setSelectedRole: (state: CreateUserModalSchema, action: PayloadAction<UserRoles>) => {
      state.selectedRole = action.payload;
    },
    setUserData: (state: CreateUserModalSchema, action: PayloadAction<CreateUserParams>) => {
      state.userData = action.payload;
    },
    setSelectedWarehouses: (state: CreateUserModalSchema, action: PayloadAction<string[]>) => {
      state.selectedWarehouses = action.payload;
    },
    setSelectedPermissions: (state: CreateUserModalSchema, action: PayloadAction<UserPermissions[]>) => {
      state.selectedPermissions = action.payload;
    },

    resetModalState: (state: CreateUserModalSchema) => {
      state.selectedRole = null;
      state.selectedWarehouses = [];
      state.selectedPermissions = [];
      state.userData = null;
      state.isOpened = false;
    },
  },
});

export const { actions: createUserModalActions, reducer: createUserModalReducer } = createUserModalSlice;
