import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Trans } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { showNotification } from 'app/providers/NotificationsProvider';
import { requestFormModalActions } from '../model/slice/requestFormModalSlice';
import { Modal } from 'shared/ui/Modal';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { Checkbox } from 'shared/ui/Checkbox';
import { TextArea } from 'shared/ui/TextArea';
import { Link } from 'react-router-dom';
import { getModalState } from '../model/selectors/getModalState';
import { useSendAppealMutation } from '../api/sendAppealApi';
import { RequestModalMode } from '../model/types';
import { AppLanguage } from 'app/config/i18Config/types';
import { APP_LANGUAGE_KEY } from 'shared/utils/constants';
import { getLoggedUserData } from 'entities/User';
import { LeadData, useCreateLeadMutation } from 'entities/Lead';
import { AppealData } from 'entities/Appeal';
import { getBoxInfo, getWarehouseInfo } from 'pages/BookingPage';
import { BookingDocumentsNames } from 'app/appState/model/types';
import { GratitudeModalType, useOpenGratitudeModal } from 'features/GratitudeModal';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { getGoogleClickIdDateFromCookie, getGoogleClickIdFromCookie } from 'features/GoogleClickId';

export const RequestFormModal: FC = memo(() => {
  const { t } = useAppTranslation(['booking', 'common']);
  const dispatch = useAppDispatch();

  const modalState = useAppSelector(getModalState);
  const globalSettings = useAppSelector(getGlobalSettings);
  const loggedUserData = useAppSelector(getLoggedUserData);
  const warehouseData = useAppSelector(getWarehouseInfo);
  const boxData = useAppSelector(getBoxInfo);

  const [sendRequest, { isLoading }] = useSendAppealMutation();
  const [createLead] = useCreateLeadMutation();
  const [isAgreementChecked, setAgreementChecked] = useState<boolean>(false);

  const { openGratitudeModal } = useOpenGratitudeModal();

  const [form] = Form.useForm();

  const handleClose = useCallback((): void => {
    dispatch(requestFormModalActions.closeRequestModal());
  }, [dispatch]);

  useEffect(() => {
    loggedUserData ? form.setFieldsValue(loggedUserData) : form.resetFields();
  }, [loggedUserData, form]);

  const isContactUsMode = modalState.mode === RequestModalMode.CONTACT_US;

  const isRequestBoxMode = modalState.mode === RequestModalMode.REQUEST_BOX;

  const handleOpenGratitudeModal = useCallback((): void => {
    openGratitudeModal({
      description: t('We have received your request. We will reach out to you soon!'),
      type: GratitudeModalType.CONTACT_FORM,
      buttonId: isRequestBoxMode ? 'request box id' : 'request offer id', // TODO ADD ID FROM MARCUS !!!
    });
  }, [isRequestBoxMode, openGratitudeModal, t]);

  const handleSubmit = useCallback(
    async (data: AppealData | LeadData): Promise<void> => {
      try {
        const language = localStorage.getItem(APP_LANGUAGE_KEY) as AppLanguage;

        if (isContactUsMode) {
          const requestData = {
            ...data,
            language,
          };

          await sendRequest(requestData as AppealData).unwrap();
        } else {
          const leadData: LeadData = {
            ...data,
            language,
            warehouseId: warehouseData?.warehouseId,
            square: modalState?.square || boxData?.sizeCode?.square,
            googleClickId: getGoogleClickIdFromCookie(),
            googleClickIdDate: getGoogleClickIdDateFromCookie(),
          };

          await createLead(leadData).unwrap();
        }

        handleClose();
        showNotification('info', t('Success', { ns: 'common' }), t('Your request has been successfully sent', { ns: 'common' }));
        form.resetFields(['note']);

        !isContactUsMode && handleOpenGratitudeModal();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error', { ns: 'common' }), t('Error when sending request', { ns: 'common' }));
      }
    },
    [
      isContactUsMode,
      handleClose,
      t,
      form,
      handleOpenGratitudeModal,
      sendRequest,
      warehouseData?.warehouseId,
      modalState?.square,
      boxData?.sizeCode?.square,
      createLead,
    ],
  );

  const privacyPolicyLink = useMemo(
    () => globalSettings?.bookingDocuments.find((el) => el.docName === BookingDocumentsNames.PRIVACY_POLICY)?.link || '#',
    [globalSettings],
  );

  const handleAgreePrivacyPolicy = useCallback((isChecked: boolean): void => {
    setAgreementChecked(isChecked);
  }, []);

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col">
        <div className="flex flex-col gap-4">
          {modalState.title && <div className="text-2xl">{modalState.title}</div>}
          {modalState.description && <div className="text-primaryLight">{modalState.description}</div>}
        </div>
        <Divider />

        <Form.Item label={t('Name')} name="firstName" rules={[{ required: true, message: `${t('Please, enter your name')}!` }]}>
          <Input placeholder={t('Please, enter your name')} bordered disabled={Boolean(loggedUserData?.firstName)} />
        </Form.Item>

        <Form.Item label={t('Last name')} name="lastName" rules={[{ required: true, message: `${t('Please, enter your last name')}!` }]}>
          <Input placeholder={t('Please, enter your last name')} bordered disabled={Boolean(loggedUserData?.lastName)} />
        </Form.Item>

        <Form.Item
          label={t('Email')}
          name="email"
          rules={[
            { required: true, message: `${t('Please, enter your email')}!` },
            { type: 'email', message: t('Please enter correct email!') },
          ]}
        >
          <Input placeholder={t('Please, enter your email address')} bordered disabled={Boolean(loggedUserData?.email)} />
        </Form.Item>

        <Form.Item
          label={t('Phone')}
          name="phone"
          rules={[
            { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number!') },
            { required: true, message: `${t('Please, enter your phone')}!` },
          ]}
        >
          <PhoneInput placeholder={t('Please, enter your phone number')} bordered />
        </Form.Item>

        <Form.Item label={t('You can enter your note below:')} name="note">
          <TextArea placeholder={t('Please, enter your note')} rows={4} bordered />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: t('You must agree with the Privacy Policy!'),
            },
          ]}
        >
          <Checkbox name="agreement" checked={isAgreementChecked} onChange={handleAgreePrivacyPolicy}>
            <Trans
              t={t}
              i18nKey="I have read and agree with the <0>Privacy Policy</0>"
              components={[<Link key="0" className="text-accent text-sm" to={privacyPolicyLink} target="_blank" />]}
            />
          </Checkbox>
        </Form.Item>

        <div className="flex justify-end gap-3 mt-2">
          <Button theme="secondary" onClick={handleClose}>
            {t('Cancel', { ns: 'common' })}
          </Button>

          <Button type="submit" isLoading={isLoading} isDisabled={!isAgreementChecked}>
            {t('Send', { ns: 'common' })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});
