import { api } from 'app/config/apiConfig';

import { Box } from '../model/types';

export const updateBoxNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateBoxNote: builder.mutation<Box, Partial<Box>>({
      query: (box) => ({
        url: `/boxes/${box.boxId}/note`,
        method: 'PUT',
        body: box,
      }),
      invalidatesTags: ['Boxes', 'Available boxes'],
    }),
  }),
});

export const { useUpdateBoxNoteMutation } = updateBoxNoteApi;
